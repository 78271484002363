import React from 'react'
import { Draggable } from 'react-beautiful-dnd';
import IconoPaginadorUltimo from '../../../assets/iconos/IconoPaginadorUltimo';
import imgRecoleccionActivo from '../../../assets/iconos/recoleccionActivo.svg'
import imgEntregaActivo from '../../../assets/iconos/entregaActivo.svg'
import imgCobranzaActivo from '../../../assets/iconos/cobranzaActivo.svg'
import imgBancoActivo from '../../../assets/iconos/bancoActivo.svg'
import imgOtroActivo from '../../../assets/iconos/otroActivo.svg'
import abrir from '../../../assets/iconos/abrir.svg'
import ProgressBar from './ProgressBar';

const ListaDraggable = (props) => {

  const imagesTypePetition = (type) => {
    if (type == 1) {
      return (imgRecoleccionActivo)
    }
    if (type == 2) {
      return (imgEntregaActivo)
    }
    if (type == 3) {
      return (imgBancoActivo)
    }
    if (type == 4) {
      return (imgCobranzaActivo)
    }
    if (type == 5) {
      return (imgOtroActivo)
    }
  }

  return (
    <div>
      <Draggable
        draggableId={`${props.peticion.id}`}
        key={props.peticion.id}
        index={props.index} style={{ cursor: 'pointer !important' }}
        isDragDisabled={props.peticion.estatus === "T" ? true : props.peticion.estatus === "C" ? true : false}
      >
        {(provided, snapshot) => (
          // <div

          // className={[`CardSinglePetitions ${props.peticion.estatus === "R" ? "bkAmarillo" : props.peticion.estatus === "D" ? "bkazul" : "BkBlanco"}`]}
          //   {...provided.draggableProps}
          //   {...provided.dragHandleProps}
          //   ref={provided.innerRef}
          //   isDragging={snapshot.isDragging}
          // >
          //   <div style={{ display: 'flex', alignItems: 'center' }}>
          //     <div className='containerAbsoluteOrigen'>
          //       <img src={props.peticion.urlorigen} alt="" />
          //     </div>
          //     <div>
          //       <p style={{
          //         color: '#151B4E',
          //         fontWeight: 'bold',
          //         fontSize: '14px',
          //         textAlign: 'center'
          //       }}>{props.peticion.cliente}</p>
          //     </div>

          //   </div>

          //   <div>
          //     <div style={{
          //       display: 'flex',
          //       flexDirection: 'row',
          //       justifyContent: 'space-between',
          //       alignItems: 'center',
          //       paddingBottom: '10px',
          //       cursor: 'pointer'
          //     }}>
          //       <div className='ContainerObservaciones'>
          //         <p>
          //         {props.peticion.observaciones === null ? "Sin observación" : props.peticion.observaciones}
          //         </p>
          //       </div>
          //       <div
          //         onClick={() => props.AbrirModal(props.peticion)}
          //         style={{
          //           width: '20px',
          //           height: '20px'
          //         }}
          //        >
          //         <IconoPaginadorUltimo></IconoPaginadorUltimo>
          //         </div>
          //     </div>
          //   </div>
          //   {provided.placeholder}

          //   <div className='EstatusPeticionCirculo' style={{ background:  props.peticion?.estatus === "A" ? "#3BAF29" :
          //       props.peticion?.estatus === "R" ? "#FFB531" :
          //       props.peticion?.estatus === "D" ? "#35C1ED" :
          //       props.peticion?.estatus === "T" ? "#151B4E" :
          //       props.peticion?.estatus === "C" ? "#B40000" : "#9597A7"}}>
          //    <img src={imgBancoActivo}/>
          //   </div>
          // </div>
          <div
            className={[`CardSinglePetitions ${props.peticion.estatus === "R" ? "bkAmarillo" : props.peticion.estatus === "D" ? "bkazul" : "BkBlanco"}`]}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div style={{
              display: 'flex', alignItems: 'center'
            }}>
              <img style={{ width: '50px' }}
                src={imagesTypePetition(props.peticion.idTipoPeticion)}
              ></img>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ width: 146, paddingLeft: 10, marginTop: '0px', textTransform: 'uppercase', fontSize: '14px', color: '#151B4E', fontWeight: 'bold' }} >{props.peticion.cliente}</p>
              </div>
            </div>
            <div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '10px',
                cursor: 'pointer'
              }}>
                <div className='ContainerObservaciones'>
                  <p>
                    {props.peticion.observaciones === null ? "Sin observación" : props.peticion.observaciones}
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='ContainerProgressbar'
                  style={{
                    width: '90%',
                    display: 'flex'
                  }}>
                  <ProgressBar
                    estatus={props.peticion?.estatus}
                  ></ProgressBar>
                </div>
                <div
                  className='containerAbsoluteOrigen'
                  onClick={() => props.AbrirModal(props.peticion)}
                  style={{
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div className="flip-card">
                    <div
                      className="front"
                      style={{ backgroundImage: `url(${abrir})` }}
                    ></div>
                    <div
                      className="back"
                      style={{ backgroundImage: `url(${props.peticion.urlorigen})` }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {provided.placeholder}
            <div>
            </div>
          </div>

        )}
      </Draggable>
    </div>
  )
}

export default ListaDraggable