import React from 'react'

const ProgressBar = (props) => {
    return (
        <div>
            <ul style={props.estatus == "A" ? { display: 'flex' } : { display: 'none' }} className='ContainerEstatusProgressbar'>
                <li className='AceptedProgressBar'></li>
            </ul>
            <ul style={props.estatus == "R" ? { display: 'flex' } : { display: 'none' }} className='ContainerEstatusProgressbar'>
                <li className='AceptedProgressBar'></li>
                <li className='RecoProgressBar'></li>
            </ul>
            <ul style={props.estatus == "D" ? { display: 'flex' } : { display: 'none' }} className='ContainerEstatusProgressbar'>
                <li className='AceptedProgressBar'></li>
                <li className='RecoProgressBar'></li>
                <li className='DocuProgressBar'></li>
            </ul>
            <ul style={props.estatus == "T" ? { display: 'flex' } : { display: 'none' }} className='ContainerEstatusProgressbar'>
                <li className='AceptedProgressBar'></li>
                <li className='RecoProgressBar'></li>
                <li className='DocuProgressBar'></li>
                <li className='FinishProgressBar'></li>
            </ul>
            <ul style={props.estatus == "C" ? { display: 'flex' } : { display: 'none' }} className='ContainerEstatusProgressbar'>
                <li className='CancelProgressBar'></li>
            </ul>
            <ul style={props.estatus == "P" ? { display: 'flex' } : { display: 'none' }} className='ContainerEstatusProgressbar'>
                <li className='PendientProgressBar'></li>
            </ul>
        </div>
    )
}

export default ProgressBar
