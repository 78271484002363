import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { MapaMonitorLayout, Layoutcontainer, PeticionesLayout } from '../theme/Layout'
import MapaMonitor from '../components/Mapa/MapaMonitor'
import { useAuth } from '../context/authcontext'
import UsePeticiones from '../components/hooks/UsePeticiones'
import UseDirections from '../components/Mapa/hooks/UseDirections'
import useGetZona from '../components/Mapa/hooks/useGetZona'
import useSucursalesActivas from '../components/hooks/useSucursalesActivas'
import UseRecolectores from '../components/hooks/UseRecolectores'
import { UseAgregarReco } from '../components/hooks/useAgregarReco'
import useErrorAlert from '../utils/hooks/useErrorAlert'
import moment from 'moment'
import SucrusalIcon from '../assets/imagenes/sucursalnvo.webp'
import useCiudades from '../components/hooks/useCiudades'
import NuevoIcon from '../assets/iconos/NuevoIcon'
import editarIcon from '../assets/iconos/EditarIcon.svg'
import AvatarNuevo from '../assets/imagenes/AvatarInicio.webp'
import Loader from '../utils/Components/Loader/Loader'
// import MapaZonas from '../components/Mapa/MapaZonas'

const Zonas = () => {
  let dimension = window.innerWidth;
  const navigate = useNavigate();
  const { HanlderVerMensajeError } = useErrorAlert()
  const { Ciudades, CargarCiudades } = useCiudades()
  const { CargarSucursales, SucursalesData } = useSucursalesActivas()
  const {
    LoadZonas,
    Datazona,
    handleraddcords,
    cordsaddfance,
    LoadZonasPorCiudad,
    DataZonaUsuario,
    geoJsonZona,
    NuevaZonaMapa,
    handlereditzona,
    SelectZona,
    EditarZonaMapa,
    seleccionarZona,
    HandlerCambioCoordenadas,
    LoadingZona
  } = useGetZona()
  const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))
  const { user, CiudadSeleccionada, socket, RecolectoresMapa, HandleEditReco } = useAuth()
  const {
    LoadPeticionesReco,
    LoadPeticiones,
    PeticionesMapa } = UsePeticiones()
  const { LoadRecolectores } = UseRecolectores();
  const [RecolectorSelecionado, setRecolectorSelecionado] = useState()
  const { LimpiarRuta } = UseDirections();
  const { recoGeo } = UseAgregarReco();
  const [setRutaEncurso] = useState();
  const [AbrirPeticionesPendientes, setAbrirPeticionesPendientes] = useState(false);
  const [setPeticionespendientes] = useState([])
  const [zonaSeleccionada, setzonaSeleccionada] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [nombre, setnombre] = useState('');
  const [EditarZona, setEditarZona] = useState(false)
  const [editarColor, seteditarColor] = useState()
  // const [SelectRecoGeo, setSelectRecoGeo] = useState(false)

  const HandlerAbrirPeticionesPendientes = () => {
    if (AbrirPeticionesPendientes === true) {
      setAbrirPeticionesPendientes(false)
    } else {
      setAbrirPeticionesPendientes(true)
    }
  }

  //MAPA
  const HandlerRutaEncurso = async (data) => {
    await setRutaEncurso(data)
  }

  useEffect(() => {
    if (RecolectorSelecionado) {

    } else {
      LimpiarRuta()
    }
  }, [RecolectorSelecionado])

  useEffect(() => {
    if (PeticionesMapa === undefined) {
      setTimeout(() => {
        LoadPeticiones(FechAactual, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        LoadZonas()
        CargarSucursales()
      }, 2000);
    }
  }, [PeticionesMapa])

  useEffect(() => {
    if (!DataZonaUsuario) {
      LoadZonasPorCiudad()
    }
  }, [DataZonaUsuario, LoadZonasPorCiudad])

  const SeleccionarReco = (data) => {
    if (data.id === RecolectorSelecionado?.id) {
      setRecolectorSelecionado(undefined)
    } else {
      setRecolectorSelecionado(data)
      LoadPeticionesReco(data.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }
  }

  useEffect(() => {
    LoadRecolectores(JSON.parse(localStorage.getItem("Ciudad")).idciudad)
  }, [user])

  //CIUDADES
  useEffect(() => {
    if (!Ciudades) {
      CargarCiudades()
    }
  }, [Ciudades, CargarCiudades])

  let idCiudadSeleccionada = Ciudades?.map(res => {
    return res.id
  })

  const activarZona = () => {
    if (zonaSeleccionada === true) {
      setzonaSeleccionada(false)
    } else {
      setzonaSeleccionada(true)
    }
  }

  const handlerRecoPorZona = (data) => {
    setzonaSeleccionada(data)
  }

  //NUEVA ZONA
  const HandlerNuevaZonaBtn = () => {
    navigate('/NuevaZona')

    // if (NuevaZona === true) {
    //   setNuevaZona(false)
    //   setEditarZona(false)
    // } else {
    //   setNuevaZona(true)
    //   setEditarZona(false)
    // }
  }

   //EDITAR ZONA
   const HandlerEditarZonaBtn = () => {
    navigate('/EditarZona')
  }

  const handlechanguenombre = (data) => {
    setnombre(data)
  }

  //EDITAR ZONA
  // const  HandlerCambioNombreZona = (data) => {
  // setnombre(data)
  // }

  const HanlderEditarZona = async (e) => {
    e.preventDefault()
    var dataEditarZona = JSON.stringify({
      "idciudad": CiudadSeleccionada.idciudad,
      "idfranquicia": user.idsucursal,
      "nombre": nombre,
      "color": editarColor,
      "cordenadas": cordsaddfance,
      "recolectores": recoGeo?.map(res => res.id),
      "estatus": "A"
    })
    EditarZonaMapa(dataEditarZona)
    // HanlderMensajeCorrecto('Se editó la dirección correctamente')
    // LimpiarNueva()
  }

  // const HandlerCambioCoordenadas = () => {

  // }
  
  const HanlderCambioColor = (data) => {
    seteditarColor(data)
  }

  // const HanlderCambioRecolectores = (data) => {
  //   seteditarRecolector(data)
  // }

  const HandlerSelectZona = async (resp) => {
    SelectZona(resp)
    handlechanguenombre(resp.observaciones)
    HandlerCambioCoordenadas(resp.cords)
    HanlderCambioColor(resp.color)
    // HanlderCambioRecolectores(resp.recolectores)
  }

  const HandlerEditar = (resp) => {
    HandlerSelectZona(resp)
    banderaEditar()
  }
  const banderaEditar = () => {
    if (EditarZona === true) {
      setEditarZona(false)
    } else {
      setEditarZona(true)
    }
  }

  return (
    <MapaMonitorLayout
      SucrusalIcon={SucrusalIcon}
    >
      <Layoutcontainer>
        <div className="containerZonas" style={{ position: 'relative' }}>
          <div className='ContainerMapaZonas'>
            <div className='containermonitorZonas'>
              <MapaMonitor
                Datazona={Datazona}
                Sucursales={SucursalesData}
                GeoJsonZona={geoJsonZona}
                Accionguardarcordenada={handleraddcords}
                Zoom="10"
              ></MapaMonitor>
              <div className='iconNuevaZona' onClick={() => HandlerNuevaZonaBtn()}>
                <NuevoIcon width={35} height={35}></NuevoIcon>
              </div>
              {/* <div className='iconEditarZona' onClick={() => HandlerEditarZonaBtn() }>
                <img src={editarIcon}/>
              </div> */}
              <div className='contenedorInfoNuevaZona' style={{ cursor: 'auto' }}>
                <div className="contenedorNuevaZona">
                  <div className="tituloZona">
                    <p>Zonas</p>
                  </div>
                  {/* CONTENEDOR ZONAS */}
                  <div style={{ paddingBottom: '10px' }} className="contenedorRecolectoresNuevaZona">
                    <div className="contenedorSeleccionarRecoZona">
                      {
                        DataZonaUsuario?.map((resp, index) => {
                          // console.log(resp, index, "respindex")
                          return (
                            <>
                              <div className="containerIndicadorZona">
                                <span className="IndicadorZona" style={{ borderRadius: '100%', height: '15px', width: '15px', background: resp.color, marginBottom: '2px', marginRight: '5px' }}></span>
                                <div style={{ width: '145px', textAlign: 'left' }}>
                                  <p style={{ cursor: 'pointer' }} onClick={() => handlerRecoPorZona(index)}>{resp.observaciones}</p></div>
                          
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                  {/* CONTENEDOR SELECCIONAR RECOLECTORES NUEVA ZONA */}

                  {/* CONTENEDOR RECOLECTORES DE CADA ZONA */}
                  <div className="contenedorRecolectoresNuevaZona" style={{ paddingBottom: '0px', paddingTop: '1px' }}>
                    <p>Recolectores</p>
                    {
                      DataZonaUsuario?.map((resp, index) => {
                        if (index === zonaSeleccionada) {
                          return (
                            <>
                              <div className="IndicadorZona" style={{ width: '60%' }}>
                                <p style={{ color: '#424141', margin: '0px', paddingLeft: '0px', paddingBottom: "5px" }}>{resp.recolectores[0]?.nombre}</p>
                                <p style={{ color: '#424141', margin: '0px', paddingLeft: '0px', paddingBottom: "5px" }}>{resp.recolectores[1]?.nombre}</p>
                                <p style={{ color: '#424141', margin: '0px', paddingLeft: '0px', paddingBottom: "5px" }}>{resp.recolectores[2]?.nombre}</p>
                                <p style={{ color: '#424141', margin: '0px', paddingLeft: '0px', paddingBottom: "5px" }}>{resp.recolectores[3]?.nombre}</p>
                                <p style={{ color: '#424141', margin: '0px', paddingLeft: '0px', paddingBottom: "5px" }}>{resp.recolectores[4]?.nombre}</p>
                                <p style={{ color: '#424141', margin: '0px', paddingLeft: '0px', paddingBottom: "5px" }}>{resp.recolectores[5]?.nombre}</p>
                              </div>
                            </>)
                        }
                      })
                    }
                  </div>
                  {/* CONTENEDOR GUARDAR NUEVA ZONA */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </Layoutcontainer>
    </MapaMonitorLayout>
  )
}

export default Zonas