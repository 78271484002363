import React,{useState,useEffect} from 'react';
import axios from 'axios';
import moment from 'moment'
import config from '../../config/config';
import { useAuth } from '../../context/authcontext';
import useHttpsEstatus from '../../utils/hooks/useHttpsEstatus';
const UsePeticiones = () => {
    const{HanlderHttpEstatus}=useHttpsEstatus()
    const{socket,RefreshTokenFunction}=useAuth()
    const [Peticiones, setPeticiones] = useState();
    const [Alertas, setAlertas] = useState();
    const [loadingpeticiones, setloadingpeticiones] = useState(false)
    const [totalregistros, settotalregistros] = useState()
    const [perpages, setperpages] = useState()
    const [totalpaginas, settotalpaginas] = useState()
    const [Pagina, setPagina] = useState();
    const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))
    const [PeticionesPendientes, setPeticionesPendientes] = useState()
    //paginador
    const [city, setcity] = useState()
    const [Search, setSearch] = useState()
    const [FechaBuscador, setFechaBuscador] = useState(moment().format('YYYY-MM-DD'))
    const [Page, setPage] = useState(1);
    const [Pages, setPages] = useState()
    const [registerperpage, setregisterperpage] = useState(10)
    const [TOTALdeRegistros, setTOTALdeRegistros] = useState()
    const [FiltroEstatus, setFiltroEstatus] = useState("Todos")

    // PETICIONES RECO ANALITICA
    const [PeticionesMapa, setPeticionesMapa] = useState()
    const [PeticionesActivas, setPeticionesActivas] = useState();
    const [PeticionesTerminadas, setPeticionesTerminadas] = useState();
    const [PeticionesCanceladas, setPeticionesCanceladas] = useState();
    const [PeticionesRecolectando, setPeticionesRecolectando] = useState();
    const [PeticionesDocumenando, setPeticionesDocumenando] = useState()

  
    // Loading 
    const [LoadingAñadirnueva, setLoadingAñadirnueva] = useState(false)
    useEffect(() => {
      if (!socket) return;
      socket.on("UpdatedPicker", (marcadores) => {
   
      });
  }, [socket]);

  const hanldernext = (num) => {
    let next = Page + num;
    if(next <= Pages){
      setPage(next)
      LoadPeticiones(FechaBuscador,JSON.parse(localStorage.getItem("Ciudad")).idciudad,Search,next,registerperpage)
    }
}    
const handlerprev = (num) =>{
  let prev = Page - num;
  if (prev > 0) {
    setPage(prev)
    LoadPeticiones(FechaBuscador,JSON.parse(localStorage.getItem("Ciudad")).idciudad,Search,prev,registerperpage)
  }
}
const HanlcerCambioFiltroEstatus = (data) =>{
  setFiltroEstatus(data)
}
const HanlderChangueRegistros = (data) => {
  setregisterperpage(data)
  setPage(1)
  LoadPeticiones(FechaBuscador,JSON.parse(localStorage.getItem("Ciudad")).idciudad,Search,1,data)
}
const HanlderCambioFecha = (data) => {
  setFechaBuscador(data)
}
const HanlderBuscador = (data) => {
  setSearch(data)
}
const HanlderChangueResultados = (num) =>{

  const ArrayResultados = Array.from({ length: num / 10 }, (_, index) => (index + 1) * 10);
  ArrayResultados.push(num)
  setTOTALdeRegistros(ArrayResultados)
}
    const LoadAlertas = async() =>{
        let isMounted = true;
        const source = axios.CancelToken.source();
        let URLALERTA = `${config.API_URL}Geolocalizacion/paginator/petition/${FechaBuscador}/${JSON.parse(localStorage.getItem("Ciudad")).idciudad}?&page=1&registerbypage=1000`
        setTimeout(() => {
            source.cancel('Request timed out');
        }, 5000);
        try {
         
            const responseAlertas = await axios.get(URLALERTA, {
                cancelToken: source.token
              });
              
              if (isMounted) {
               
                if(responseAlertas.status === 204){
                    setAlertas([])
                }else{

                    setAlertas(
                        responseAlertas.data.resultado.filter(datos => datos.estatus === "A" || datos.estatus === "R" || datos.estatus === "P" || datos.estatus === "D")
                      )
                      setPeticionesPendientes(
                        responseAlertas.data.resultado.filter(datos => datos.estatus === "P")
                      )
                      setPeticionesTerminadas(
                        responseAlertas.data.resultado.filter(datos => datos.estatus === "T")
                      )
                      setPeticionesActivas(
                        responseAlertas.data.resultado.filter(datos => datos.estatus === "A" || datos.estatus === "R" || datos.estatus === "C" || datos.estatus === "D" || datos.estatus === "T")
                      )
                      setPeticionesCanceladas(
                        responseAlertas.data.resultado.filter(datos => datos.estatus === "C")
                      )
                }
               
              }
        } catch (error) {
            
        }
    }
    const LoadPeticiones = async(fecha,ciudad,busqueda,pagina,registros) =>{
      let isMounted = true;
      const source = axios.CancelToken.source();
      let URLALERTA = `${config.API_URL}Geolocalizacion/paginator/petition/${fecha}/${ciudad}?search=${busqueda === undefined ? '' : busqueda}&page=${pagina}&registerbypage=${registros === undefined ? '1000' : registros}`
      setTimeout(() => {
          source.cancel('Request timed out');
      }, 15000);
      try {
       
          const responseAlertas = await axios.get(URLALERTA, {
              cancelToken: source.token
            });
            
            if (isMounted) {
             
              if(responseAlertas.status === 204){
                setPeticionesMapa([])
              }else{
                setPeticionesMapa(responseAlertas.data.resultado)
                setPages(responseAlertas.data.totalPaginas)
                HanlderChangueResultados(responseAlertas.data.totalRegistros)
              }
            }
      } catch (error) {
        console.error("ERROR DE PETICION", error)
      }
  }

    const LoadPeticionesReco = async(idReco,Ciudad) =>{
        let isMounted = true;
        const source = axios.CancelToken.source();
        let URLPETICIONESUSUARIO = `${config.API_URL}Geolocalizacion/petitionbyuser/${idReco}/${Ciudad}`
        setTimeout(() => {
            source.cancel('Request timed out');
        }, 5000);
        try {
          const responsePeticionesRecolector = await axios.get(URLPETICIONESUSUARIO, {
              cancelToken: source.token
            });
            
            if (isMounted) {
                
              if(responsePeticionesRecolector.status === 204){
                setPeticionesActivas([])
                setPeticionesRecolectando([])
                setPeticionesDocumenando([])
              }else{
               
                setPeticionesActivas(
                  responsePeticionesRecolector.data.filter(datos => datos.estatus === "A" || datos.estatus === "R" || datos.estatus === "C" || datos.estatus === "D" || datos.estatus === "T")
                )
                setPeticionesTerminadas(
                  responsePeticionesRecolector.data.filter(datos => datos.estatus === "T")
                )
                setPeticionesCanceladas(
                  responsePeticionesRecolector.data.filter(datos => datos.estatus === "C")
                )
                setPeticionesPendientes(
                  responsePeticionesRecolector.data.filter(datos => datos.estatus === "P")
                )
                setPeticionesRecolectando(
                  responsePeticionesRecolector.data.filter(datos => datos.estatus === "R")
                )
                setPeticionesDocumenando(
                  responsePeticionesRecolector.data.filter(datos => datos.estatus === "D")
                )
              }
             
            }
        } catch (error) {
          
        }
    }

    const NuevaPeticion = async(request) =>{
   
      setLoadingAñadirnueva(true)
      // socket.invoke('NewPetition', { id: 125 })
        let isMounted = true;
        const source = axios.CancelToken.source();
        let URL_ADDPETTITION = `${config.API_URL}Geolocalizacion/addpetition`
        try {
          const AñadirPeticion = await axios.post(URL_ADDPETTITION,request,{
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
              },
        })
        if(isMounted){
          setTimeout(async() => {
            setLoadingAñadirnueva(false)
            await HanlderHttpEstatus(AñadirPeticion.status,`la petición fue creada id${AñadirPeticion.data.idPeticion}`)
            socket.invoke('NewPetition', { id: AñadirPeticion.data.idPeticion })
          }, 3000);
         
        }
        } catch (error) {
          if(error.response.status === 401){
           
            RefreshTokenFunction()
            setTimeout(async() => {
              try {
                const AñadirPeticion = await axios.post(URL_ADDPETTITION,request,{
                  headers: { 
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
                    },

                    
              })
              await HanlderHttpEstatus(AñadirPeticion.status,`la petición fue creada id${AñadirPeticion.data.idPeticion}`)
              setLoadingAñadirnueva(false)
              socket.invoke('NewPetition', { id: AñadirPeticion.data.idPeticion })
              } catch (error) {
                
              }
            }, 3000);
          }
            // await HanlderHttpEstatus(error.response.status,'ERROR')
            console.error(error)
        }
    }

    const ActualizarPeticion = async(idpeticion,idusuario,estatus,responsable,motivo) =>{
       let Request = {
            "id": idpeticion,
            "idPicker": idusuario,
            "status": estatus,
            "motivo": estatus == "C" ? 'Cliente cancelo el envìo' : '' ,
            "idUser": responsable
       }
       let isMounted = true;
       const source = axios.CancelToken.source();
       let URL_ACTUALIZARPETICION = `${config.API_SOCKET}/api/Petition/UpdateStatus`
      
       try {
        const ActualizarPEticion = await axios.post(URL_ACTUALIZARPETICION,Request,{
          headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
            },
      })
       } catch (error) {
          console.error(error)
       }
      
    }
  return {
    LoadAlertas,
    Alertas,
    LoadPeticionesReco,
    LoadPeticiones,
    PeticionesMapa,
    PeticionesPendientes,
    PeticionesActivas,
    PeticionesTerminadas,
    PeticionesCanceladas,
    PeticionesDocumenando,
    PeticionesRecolectando,
    NuevaPeticion,
    ActualizarPeticion,
    LoadingAñadirnueva,
    Page,
    Pages,
    hanldernext,
    handlerprev,
    TOTALdeRegistros,
    HanlderChangueRegistros,
    HanlderCambioFecha,
    FechaBuscador,
    HanlderBuscador,
    Search,
    HanlcerCambioFiltroEstatus,
    FiltroEstatus
  }
}

export default UsePeticiones