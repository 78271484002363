import React, { useState } from 'react'
import config from '../../config/config';
import useHttpsEstatus from '../../utils/hooks/useHttpsEstatus';
import axios from 'axios'
const useAutocompletecp = () => {
  const [Datacp, setDatacp] = useState();
  const [LoadingCp, setLoadingCp] = useState(false)
  const { HanlderHttpEstatus } = useHttpsEstatus()
  const CargarCodigoPostales = async (datos) => {
    setLoadingCp(true)
    try {
      let isMounted = true;
      const source = axios.CancelToken.source();
      const URL_OBTENERCODIGO_POSTAL = `${config.API_URL}CodigoPostal/getcp?search=${datos}&country=mx`
      const Responsecodigopostal = await axios.get(URL_OBTENERCODIGO_POSTAL, {
        cancelToken: source.token
      });
      if (isMounted) {
        await HanlderHttpEstatus(Responsecodigopostal.status, '', 'Buscador')
        setDatacp(Responsecodigopostal.data)
        setLoadingCp(false)
      }
    } catch (error) {
      await HanlderHttpEstatus(error.response.status, '')
      console.error(error)
    }
  }
  return {
    CargarCodigoPostales,
    Datacp,
    LoadingCp
  }
}

export default useAutocompletecp