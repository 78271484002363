import React, { useRef, useState, useEffect, useCallback } from 'react'
import mapboxgl, { Marker } from 'mapbox-gl';
import { v4 } from 'uuid';
import * as turf from "@turf/turf";
import { Subject } from 'rxjs';
// import API_URL from '../config';
import imgRecoleccionActivo from '../../../assets/iconos/recoleccionActivo.svg'
import imgEntregaActivo from '../../../assets/iconos/entregaActivo.svg'
import imgCobranzaActivo from '../../../assets/iconos/cobranzaActivo.svg'
import imgBancoActivo from '../../../assets/iconos/bancoActivo.svg'
import imgOtroActivo from '../../../assets/iconos/otroActivo.svg'
mapboxgl.accessToken = 'pk.eyJ1IjoicGt0MWRldmVsb3BlciIsImEiOiJjbDZ6OXVjbzgwMmFrM3BsY3FweGkyYWJzIn0.r8Iu1ZteE0WwYt1LFfT1yg';

export const useMapMonitorNvo = (socket, Recolectores, rutareco, rutaencurso, PeticionesMapa, AccionEditarReco, Recolectorselecionado, TipoMapa, Sucursales, DataZonaUsuario, Zoom) => {
    let RecolectorSelected = Recolectorselecionado;
    // COORDENADA INICIAL 
    const [puntoinicial, setpuntoinicial] = useState(JSON.parse(localStorage.getItem('coordenadamapa')));
    // REFERENCIAS
    const SucursalesIcon = useRef({});
    // ESTADOS ACTIVOS
    const [isTabActive, setIsTabActive] = useState(true);
    const [isInactive, setIsInactive] = useState(false);
    const [Vehiculo, setVehiculo] = useState()
    // CAMBIOS EN LA VISIBILIDAD DE LA PESTAÑA DEL NAVEGADOR
    let inactivityTime = 30 * 60 * 1000; // = 30min.
    let timeoutId;

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsTabActive(!document.hidden);
        };
        document.addEventListener('visibilitychange', handleVisibilityChange); // Este evento se dispara cada vez que la pestaña cambia entre visible y oculta
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange); // Esto elimina el event listener para prevenir posibles fugas de memoria o comportamientos inesperados
        };
    }, []);

    const resetTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(setInactive, inactivityTime);
    };
    const setInactive = () => {
        setIsInactive(true);
        window.location.reload()
        // EJECUTAR MODAL DE INACTIVIDAD Y REFRESH

    };
    useEffect(() => {
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('mousedown', resetTimer);
        document.addEventListener('keypress', resetTimer);
        document.addEventListener('touchmove', resetTimer);
        timeoutId = setTimeout(setInactive, inactivityTime);
        return () => {
            document.removeEventListener('mousemove', resetTimer);
            document.removeEventListener('mousedown', resetTimer);
            document.removeEventListener('keypress', resetTimer);
            document.removeEventListener('touchmove', resetTimer);
            clearTimeout(timeoutId);
        };
    }, []);

    // EFECTOS PRINCIPALES PARA INACTIVIDAD Y REFRESH
    // EFECTO QUE RENUDAR LA CONEXION SIMPRE Y CUANDO SE CAMBIE LA PESTAÑA
    useEffect(() => {

        if (isTabActive) {
            // Reanudar actualizaciones en tiempo real
            if (!socket) return;
            if (!socket) return;
            socket.on("ActivePickers", (recosEntrentes) => {

                recosEntrentes?.map((res, index) => {

                    // HandlerChangueColor(res.status, res.id)
                })
            });
            setTimeout(() => {
            }, 2500);
        } else {
            // Cuando no este activo
        }
    }, [isTabActive]);

    // AGREGAR MAPA
    const mapaDiv = useRef();
    const mapa = useRef();
    const [coords, setCoords] = useState(puntoinicial);
    const [iszonaclick, setiszonaclick] = useState(false)
    const setRef = useCallback((node) => {
        mapaDiv.current = node;
    }, []);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapaDiv.current,
            // style: 'mapbox://styles/mapbox/streets-v12',
            style: 'mapbox://styles/pkt1developer/cm0l8z2pl025m01qrcvkn3yqz?optimize=true',
            center: [-102.6012034945277, 24.007726083194417],
            zoom: Zoom,
            minZoom: Zoom
        });
        mapa.current = map;
    }, [])

    // AÑADIR RECOLECTORES AL MAPA
    const marcadoresRecolector = useRef({})
    const marcadorNuevo = useRef(new Subject())
    var test = new Array;
    const eliminarMarcador = (res) => {
        // Eliminar el marcador anterior si ya existe
        if (marcadoresRecolector.current[res.id]) {
            marcadoresRecolector.current[res.id].remove();
            delete marcadoresRecolector.current[res.id];
        }

    }
    const AgregarMarcador = useCallback((ev, id) => {
        const { lng, lat } = ev.lngLat || ev;
        const nameReco = ev.name;
        const statusReco = ev.status;  // Asume que el estado del recolector`
        const identreco = ev.identreco;
        // console.log(ev, "ev")
        // TOMAR INCIALES DEL NOMBRE DE RECOLECTOR
        const inicialesReco = nameReco
            .split(' ') // Dividir el texto en un array de palabras
            // .slice(0, 2) // Tomar solo las primeras dos palabras
            .map(palabra => palabra.charAt(0)) // Obtener la primera letra de cada palabra
            .join(''); // Unir las iniciales en una sola cadena

        const RecoIcon = document.createElement('div');
        RecoIcon.className = `vehiculoKANGOO`;

        // Aplicar el color dinámico al fondo del marcador
        const marker = new mapboxgl.Marker(RecoIcon);
        const popupPeticionesReco = new mapboxgl.Popup({ closeOnClick: false, closeButton: false, offset: 25 })
        popupPeticionesReco.setHTML(""); // Esto limpia el contenido actual del popup
        popupPeticionesReco.setHTML(
            `
                 <div class='ContainerCardRecoMapa'>
                  <div class=${statusReco === "L" ? "popup-tipA" :
                statusReco === "R" ? "popup-tipR" :
                    statusReco === "D" ? "popup-tipD" :
                        "popup-tipI"}> </div>
                 <div class=${statusReco === "L" ? "RecoAct" :
                statusReco === "R" ? "RecoRec" :
                    statusReco === "D" ? "RecoDoc" :
                        "RecoInc"}>
                  <div class='ContainerTextoReco'>
                       <p>${identreco === null || identreco === "" ? "NC" : identreco}</p>
                       </div>
                 </div>
                   </div>
                `
        )
            .addTo(mapa.current);
        marker.id = ev.id ?? v4();
        // console.log(statusReco, "statusReco")
        if (!marcadoresRecolector.current[id]) {
            marker
                .setLngLat([lng, lat])
                .addTo(mapa.current)
                .setPopup(popupPeticionesReco)
            marcadoresRecolector.current[marker.id] = marker;
            // marker.getElement().addEventListener('click', () => {
            // })
            HandlerChangueVehiculo(ev.vehiculo.tipo, ev.id)
            // HandlerChangueColor(ev.status, ev.id)
            //Editarreco(marcadores.id,marcadores.lat,marcadores.lng,marcadores.status)
            // HanlderChargueFristAnalitic(ev.id, ev.cityId)
        }
        if (!id) {
            marcadorNuevo.current.next({
                id: marker.id,
                lng,
                lat
            });
        }
    }, [])
    useEffect(() => {
        setTimeout(() => {
            if (Recolectores) {
                if (TipoMapa === "City") {

                    Recolectores.map((res, index) => {
                        if (res.cityId === JSON.parse(localStorage.getItem("Ciudad")).idciudad) {
                            if (!marcadoresRecolector.current[res.id]) {
                                AgregarMarcador(res, index)
                                test = res
                            }
                        }
                    })

                } else {
                    Recolectores.map((res, index) => {
                        if (!marcadoresRecolector.current[res.id]) {
                            AgregarMarcador(res, index)
                            test = res
                        }
                    })
                }
            }
        }, 1000);
    }, [Recolectores])

    // CAMBIO VEHICULO RECOLECTOR
    const HandlerChangueVehiculo = (vehiculo, id) => {
        switch (vehiculo) {
            case "LEAF":
                marcadoresRecolector.current[id]._element.className = "vehiculoLEAF mapboxgl-marker mapboxgl-marker-anchor-center";
                break;
            case "TRANSIT":
                marcadoresRecolector.current[id]._element.className = "vehiculoTRANSIT mapboxgl-marker mapboxgl-marker-anchor-center";
                break;
            case "KIRI":
                marcadoresRecolector.current[id]._element.className = "vehiculoKIRI mapboxgl-marker mapboxgl-marker-anchor-center";
                break;
            case "ESUNRAY JAC":
                marcadoresRecolector.current[id]._element.className = "vehiculoJAC mapboxgl-marker mapboxgl-marker-anchor-center";
                break;
            default:
                marcadoresRecolector.current[id]._element.className = "vehiculoKANGOO mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
        }
    }

    // CALL BACK QUE MONITOREA EL MOVIMIENTO
    const actualizarPosicion = useCallback((datosg) => {
        let data = {
            id: datosg.id,
            lng: datosg.lng,
            lat: datosg.lat
        }
        // setisnotmarker(false)
        marcadoresRecolector.current[datosg.id].setLngLat([datosg.lng, datosg.lat]);
        // HandlerChangueColor(datosg.status, datosg.id)
        // Editarreco(datosg.id,datosg.lat,datosg.lng,datosg.status,Recolectores)
        // HanlderChargueFristAnalitic(datosg.id)

        // let pt = turf.point([datosg.lng, datosg.lat]);
    }, [])

    // FUNCION PARA CAMBIO DE CSS DE RECO
    // const HandlerChangueColor = (status, id) => {
    //     switch (status) {
    //         case "R":
    //             marcadoresRecolector.current[id]._element.className = "RecoletorR mapboxgl-marker mapboxgl-marker-anchor-center Recolectando";
    //             break;
    //         case "D":
    //             marcadoresRecolector.current[id]._element.className = "RecoletorD mapboxgl-marker mapboxgl-marker-anchor-center Documentando"
    //             break;

    //         case "I":
    //             marcadoresRecolector.current[id]._element.className = "RecoletorI mapboxgl-marker mapboxgl-marker-anchor-center Documentando"
    //             break;
    //         default:
    //             marcadoresRecolector.current[id]._element.className = "vehiculoKANGOO mapboxgl-marker mapboxgl-marker-anchor-center Libre"
    //             break;
    //     }
    // }

    // EFECTO QUE ESCUCHA EL MOVIMIENTO DEL MARCADOR
    useEffect(() => {
        if (!socket) return;
        socket.on("UpdatedPicker", (marcadoresRecolector) => {

            actualizarPosicion(marcadoresRecolector);
        });
    }, [socket]);

    useEffect(() => {
        if (!socket) return;
        socket.on("ActivePickers", (recosEntrentes) => {

            recosEntrentes?.map((res, index) => {
                eliminarMarcador(res)
                AgregarMarcador(res, index)
                // console.log(recosEntrentes, "recos")
                // HandlerChangueColor(res.status, res.id)
            })
        });
    }, [socket]);

    useEffect(() => {
        if (Recolectorselecionado) {

        } else {
            if (mapa.current.getLayer('LineString')) {
                mapa.current.removeLayer('LineString')
                mapa.current.removeSource('LineString')
            }
        }
    }, [Recolectorselecionado, mapa.current])

    // FUNCION DE POSISIONAR  RECO
    useEffect(() => {
        if (Recolectorselecionado) {
            // mapa.current?.flyTo({ zoom: Recolectorselecionado.status != "L" || Recolectorselecionado.status != "I" ? 13 : 18, center: [Recolectorselecionado.lng, Recolectorselecionado.lat], })
            // mapa.current?.flyTo({ zoom: Zoom, center: [puntoinicial.lng, puntoinicial.lat], })
               mapa.current?.flyTo({ zoom: Recolectorselecionado.status != "L" || Recolectorselecionado.status != "I" ? 11 : 18, center: [Recolectorselecionado.lng, Recolectorselecionado.lat], })
        } else {
            mapa.current?.flyTo({ zoom: Zoom, center: [puntoinicial.lng, puntoinicial.lat], })
        }

    }, [Recolectorselecionado])

    //SUCURSALES
    const AgregarSucursal = async (data) => {
        const elp = document.createElement('div');
        elp.className = 'SucursalesIconMapa';
        const SucursalesIConMap = new mapboxgl.Marker(elp);
        SucursalesIConMap.id = data.id
        SucursalesIConMap
            .setLngLat([data.longitud, data.latitud])
            .addTo(mapa.current)

        SucursalesIcon.current[SucursalesIConMap.id] = SucursalesIConMap;
    }
    useEffect(() => {
        setTimeout(() => {
            let ArraySucMapa = new Array;
            if (Sucursales) {
                // let SucursalCiudad = Sucursales?.filter(datos => datos.idciudad === JSON.parse(localStorage.getItem("Ciudad")).idciudad)
                Sucursales?.map((res, index) => {
                    if (!SucursalesIcon.current[res.id]) {
                        AgregarSucursal(res)
                        // let dt = {
                        //     id: res.id,
                        //     cords: [res.longitud, res.latitud],
                        //     nombre: res.nombre,
                        // }
                        // ArraySucMapa.push(dt)
                    }
                })
            }
        }, 1000);

    }, [Sucursales, AgregarSucursal])

    // MOVER EL MAPA
    useEffect(() => {

        mapa.current?.on('move', () => {
            const { lng, lat } = mapa.current.getCenter();
            setCoords({
                lng: lng.toFixed(4),
                lat: lat.toFixed(4),
                zoom: mapa.current.getZoom().toFixed(2)
            })
        });

    }, []);

    //PETICIONES
    const Peticiones = useRef({});
    // Función para eliminar todos los marcadores
const EliminarPeticiones = () => {
    Object.values(Peticiones.current).forEach((marcador) => {
        marcador.remove(); // Remueve el marcador del mapa
    });
    Peticiones.current = {}; // Limpia el objeto de peticiones
};
const imagesTypePetition = (type) => {
    if (type == 1) {
        return (imgRecoleccionActivo)
    }
    if (type == 2) {
        return (imgEntregaActivo)
    }
    if (type == 3) {
        return (imgBancoActivo)
    }
    if (type == 4) {
        return (imgCobranzaActivo)
    }
    if (type == 5) {
        return (imgOtroActivo)
    }
}
    const AgregarPeticion = (data) => {
        // console.log(data, "MARCADOR PETICION")
        const elp = document.createElement('div');
        elp.className = `
            ${data.status === 'R'
                ?
                'MarcadorPetR'
                :
                data.status === 'D'
                    ?
                    'MarcadorPetD'
                    :
                    data.status === 'P'
                        ?
                        'MarcadorPetP'
                        :
                        'MarcadorPetA'
            }`;
        // Div adicional para mostrar identificador de recolector en marcador
        const identificadorDiv = document.createElement('div');
        // console.log(data, "data")
        identificadorDiv.className = 'containerIdentReco'; // Define esta clase en CSS
        identificadorDiv.innerHTML = `
        <p>${data.status === "P" ? "P" : data.identreco === "" ? "NC" : data.identreco}</p>
    `;
        // Agregar el identificadorDiv al marcador (elp)
        elp.appendChild(identificadorDiv);
        const popupPeticionesReco2 = new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(
            `
                <div class='ContainerCardPetiMapa'>
                  <div class='popup-tipPeti'> </div>
                  <div class="InfoPeti"}>
                    <div class='ContainerTextoPeti'>
                     <div class='containerIconPetition'>
                     <img style={{ marginTop: '26px' }} src=${imagesTypePetition(data.idTipoPeticion)}/>
                     </div>
                        <div>
                        <p>${data.id}</p>
                        <p>${data.cliente}</p>
                        </div>
                    </div>
                  </div>
                </div>   
                `
        );
        const marcadorpeticiones = new mapboxgl.Marker(elp);
        marcadorpeticiones.id = data.id
        marcadorpeticiones
            .setLngLat(data.cords)
            .addTo(mapa.current)
            .setPopup(popupPeticionesReco2)
        Peticiones.current[marcadorpeticiones.id] = marcadorpeticiones;
    }

    useEffect(() => {
        setTimeout(() => {
            EliminarPeticiones()
            let arraypeticiones = new Array;
            if (PeticionesMapa) {
                PeticionesMapa?.map((res, index) => {
                    console.log(PeticionesMapa, "PWTIIIIIIIIIIIIIII2")
                    if (!Peticiones.current[res.id]) {
                        if (res.estatus !== "T" && res.estatus !== "C") {
                            let dt = {
                                id: res.id,
                                cords: [res.coordenadas.longitude, res.coordenadas.latitude],
                                status: res.estatus,
                                Origen: res.origen,
                                idRecolectorAsignado: res.idusuarioasignado,
                                cliente: res.cliente,
                                direccion: res.direccion,
                                fecha: res.fecha,
                                identreco: res.identreco,
                                idTipoPeticion: res.idTipoPeticion
                            }
                            arraypeticiones.push(dt)
                        }
                        arraypeticiones.map(Peticionesex => {
                            if (!Peticiones.current[Peticionesex.id]) {
                                AgregarPeticion(Peticionesex, index)
                                console.log(Peticionesex, "Peticionesex")
                            }
                        })
                    }
                })
            }
        }, 1000);

    }, [PeticionesMapa, AgregarPeticion])

    // ESCUCHA LAS ACTUALIZACIONES DE PETICION
    useEffect(() => {
        if (!socket) return;
        socket.on('UpdateStatusPetition', (data) => {
            actualizarPeticion(data)
        })
    }, [socket])

    // ACTUALIZAR PETICION
    const actualizarPeticion = (datosg) => {
        switch (datosg.estatus) {
            case "P":
                Peticiones.current[datosg.id]._element.className = "MarcadorPetP mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "A":
                Peticiones.current[datosg.id]._element.className = "MarcadorPetA mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "R":
                Peticiones.current[datosg.id]._element.className = "MarcadorPetR mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "D":
                Peticiones.current[datosg.id]._element.className = "MarcadorPetD mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "T":
                Peticiones.current[datosg.id].remove()
                break;
            case "C":
                Peticiones.current[datosg.id].remove()
                break;
            default:
                Peticiones.current[datosg.id]._element.className = "MarcadorPetA mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
        }
        // HandlerChangueColor(datosg.status, datosg.usuarioasignado)
        // HandleEditRecoPetitonCOLOR(datosg.usuarioasignado,datosg.estatus)
        // HandleEditRecoPetiton(datosg.usuarioasignado, datosg.status === "A" ? "L" : datosg.status, RecolectoresMapa === undefined ? test : RecolectoresMapa)
    }
    // RUTA RECOLECTOR
    useEffect(() => {
        if (rutareco) {
            if (mapa.current.getLayer('LineString')) {
                mapa.current.removeLayer('LineString')
                mapa.current.removeSource('LineString')
            }
            const geojson = {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'properties': {},
                            'coordinates': rutareco.routes[0].geometry.coordinates
                        }
                    }
                ]
            };

            mapa.current.addSource('LineString', {
                'type': 'geojson',
                'data': geojson
            })
            mapa.current.addLayer({
                'id': 'LineString',
                'type': 'line',
                'source': 'LineString',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': rutaencurso.estatus === "A" ? '#3BAF2A' : rutaencurso.estatus === "R" ? '#FFB531' : rutaencurso.estatus === "D" ? '#35C1ED' : '#3BAF2A',
                    'line-width': 5
                }
            });

        }

    }, [rutareco])


    // ZONAS
    useEffect(() => {
        let geocercadata;
        if (DataZonaUsuario) {

            DataZonaUsuario?.map(responsecords => {
                if (mapa.current.getSource(responsecords.observaciones)) {
                    mapa.current.removeLayer(responsecords.observaciones)
                    mapa.current.removeSource(responsecords.observaciones)
                }
                let Arraygeocerca = new Array(responsecords.cords)
                let ObjGeocerca = JSON.parse(Arraygeocerca[0]);
                const cordenadas = ObjGeocerca.map(resto => { return resto });
                geocercadata = turf.polygon(cordenadas);
                // setgeoJsonZona(geocercadata.geometry)
                mapa.current.addSource(responsecords.observaciones, {
                    "type": "geojson",
                    "data": {
                        "type": "Feature",
                        "geometry": geocercadata.geometry
                    }
                })
                mapa.current?.addLayer({
                    'id': responsecords.observaciones,
                    'type': 'fill',
                    'source': responsecords.observaciones, // reference the data source
                    'paint': {
                        'fill-color': responsecords.color, // blue color fill
                        'fill-opacity': 0.5
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
                mapa.current?.on('click', responsecords.observaciones, (e) => {
                    if (iszonaclick === false) {
                        setiszonaclick(true)
                    } else {
                        setiszonaclick(false)
                    }
                });
            })
        }
    }, [DataZonaUsuario])


    return {
        setRef,
        AgregarMarcador,
        Vehiculo    
    }
}
export default useMapMonitorNvo
