import React, { useState, useEffect } from 'react'
import config from '../../config/config'
import axios from 'axios'
import useModal from '../../utils/Components/modal/usemodal'

const useDirecciones = () => {
    const [Direcciones, setDirecciones] = useState();
    const [DireccionesRecoleccion, setDireccionesRecoleccion] = useState()
    const [PrimeraDireccion, setPrimeraDireccion] = useState()
    const [GeocoderData, setGeocoderData] = useState();
    const [DireccionSeleccionada, setDireccionSeleccionada] = useState()
    // Loading
    const [LoadingDireccionPeticion, setLoadingDireccionPeticion] = useState(false)
    const [LoadingNuevaDireccion, setLoadingNuevaDireccion] = useState(false)
    const [LoadingDireccionPeticionTes, setLoadingDireccionPeticions] = useState(false)
    //DIRECCION
    const [LatitudeDireccion, setLatitudeDireccion] = useState();
    const [LongitudeDireccion, setLongitudeDireccion] = useState();
    const [PaisDireccion, setPaisDireccion] = useState();
    const [CalleDireccion, setCalleDireccion] = useState();
    const [NumeroExtDireccion, setNumeroExtDireccion] = useState();
    const [NumeroIntDireccion, setNumeroIntDireccion] = useState();
    const [CpDireccion, setCpDireccion] = useState();
    const [ColoniaDireccion, setColoniaDireccion] = useState();
    const [CiudadDireccion, setCiudadDireccion] = useState();
    const [MunicipioDireccion, setMunicipioDireccion] = useState();
    const [EstadoDireccion, setEstadoDireccion] = useState();
    const [CorreoDireccion, setCorreoDireccion] = useState();
    const [TelefonoDireccion, setTelefonoDireccion] = useState();
    const { CerrarModalDireccionesCliente } = useModal();
    //PAGINADOR
    const [BuscarDir, setBuscarDir] = useState();
    const [cliente, setcliente] = useState();
    const [SubmenuDirecciones, setSubmenuDirecciones] = useState(false)

    const HandlerSubmenuDir = () => {
        if (SubmenuDirecciones === true) {
            setSubmenuDirecciones(false)
        } else {
            setSubmenuDirecciones(true)
        }
    }

    const HandlerBuscarDir = (data) => {
        setBuscarDir(data)
    }

    //SELECCONAR DIR
    const SeleccionarDireccion = async (data) => {
        setDireccionSeleccionada(data)
    }

    const LimpiarDireccion = async () => {
        await setPaisDireccion(undefined)
        await setCalleDireccion(undefined)
        await setNumeroExtDireccion(undefined)
        await setNumeroIntDireccion(undefined)
        await setCpDireccion(undefined)
        await setColoniaDireccion(undefined)
        await setCiudadDireccion(undefined)
        await setMunicipioDireccion(undefined)
        await setEstadoDireccion(undefined)
        await setCorreoDireccion(undefined)
        await setTelefonoDireccion(undefined)
    }

    //DIRECCION
    const HanlderCambioLatitudeDireccion = (data) => {
        setLatitudeDireccion(data)
    }
    const HanlderCambioLongitudeDireccion = (data) => {
        setLongitudeDireccion(data)
    }
    const HandlerCambioPaisDireccion = (data) => {
        setPaisDireccion(data)
    }
    const HandlerCambioCalleDireccion = (data) => {
        setCalleDireccion(data)
    }
    const HandlerCambioNumExtDireccion = (data) => {
        setNumeroExtDireccion(data)
    }
    const HandlerCambioNumIntDireccion = (data) => {
        setNumeroIntDireccion(data)
    }
    const HandlerCambioCpDireccion = (data) => {
        setCpDireccion(data)
    }
    const HandlerCambioColoniaDireccion = (data) => {
        setColoniaDireccion(data)
    }
    const HandlerCambioCiudadDireccion = (data) => {
        setCiudadDireccion(data)
    }
    const HandlerCambioMunicipioDireccion = (data) => {
        setMunicipioDireccion(data)
    }
    const HandlerCambioEstadoDireccion = (data) => {
        setEstadoDireccion(data)
    }
    const HandlerCambioCorreoDireccion = (data) => {
        setCorreoDireccion(data)
    }
    const HandlerCambioTelefonoDireccion = (data) => {
        setTelefonoDireccion(data)
    }

    const LimpiarGeocoder = async () => {
        await setGeocoderData(undefined)
    }

    // const LimpiarGeocoder = async (toEmpty = false) => {
    //     console.log(GeocoderData, "geooo")

    //     if (toEmpty) {
    //       await setGeocoderData('');
    //     } else {
    //       await setGeocoderData(undefined);
    //     }
    //     console.log(GeocoderData, "geooo")
    //   }
    // const LimpiarGeocoderNuevoCliente = () => {
    //     if (GeocoderData === undefined) {
    //         alert("limpiar cord")
    //         setGeocoderData('')
    //         console.log(GeocoderData, "geooooo")
    //     }
       
    // }


   //CARGAR DIRECCIONES
    const CargarDirecciones = async (idCliente, buscar) => {
        try {
            let isMounted = true;
            const source = axios.CancelToken.source();
            const URLDIRECCIONES = `${config.API_URL}Cliente/Address/Get/${idCliente}?search=${buscar === undefined ? '' : buscar}&page=1&registerbypage=10`
            const ResponseDirecciones = await axios.get(URLDIRECCIONES, {
                cancelToken: source.token
            });

            if (isMounted) {
                setDirecciones(ResponseDirecciones.data.resultado)
                // setcliente(ResponseDirecciones.data.resultado.idcliente)
                setPrimeraDireccion(ResponseDirecciones.data.resultado[0])
            }
        } catch (error) {
            console.error(error)
        }
    }

    //CARGAR DIRECCIONES DE RECOLECCION
    const CargarDireccionesDeRecoleccion = async (idCliente, idciudad) => {
        setLoadingDireccionPeticion(true)
        try {
            let isMounted = true;
            const source = axios.CancelToken.source();
            const URLDIRECCIONES = `${config.API_URL}Cliente/addrees/pickup/${idCliente}/${idciudad}?page=1&registerbypage=10`
            const ResponseDirecciones = await axios.get(URLDIRECCIONES, {
                cancelToken: source.token
            });

            if (isMounted) {
                setLoadingDireccionPeticion(false)
                setDireccionesRecoleccion(ResponseDirecciones.data.resultado)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // NUEVA DIRECCION
    const AñadirNuevaDireccion = async (request, Responsableid) => {
        setLoadingNuevaDireccion(true)
        try {
            let isMounted = true;
            const source = axios.CancelToken.source();
            const URL_NUEVADIRECCION = `${config.API_URL}Cliente/Address/Add/${Responsableid}`
            const ResponseNuevaDireccionRecolecion = await axios.post(URL_NUEVADIRECCION, request, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
                },
            })
            if (isMounted) {
                setLoadingNuevaDireccion(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // EDITAR DIRECCION CLIENTES
    const ActuliazarDireccionARecolecion = async (direccion, idusuario, tipo, lat, lng) => {
        setLoadingDireccionPeticion(true)
        let DireccionCadena = `${direccion.calle} ${direccion.noint === "s/n" ? direccion.noext : direccion.noin},${ColoniaDireccion},${direccion.cp},${direccion.localidad},${direccion.estado}.,${direccion.pais}`
        try {
            let isMounted = true;
            const source = axios.CancelToken.source();
            const URL_EDITARDIRECCION_A_RECO = `${config.API_URL}Cliente/Address/update/${DireccionSeleccionada.id}/${idusuario}`
            //    await GeocoderObtenerDireccion(DireccionCadena)
            const URL_GEOCODER = `${config.API_URL}Geolocalizacion/geocodereverse/${DireccionCadena}`
            const ResponseGeocoder = await axios.get(URL_GEOCODER, {
                cancelToken: source.token
            });
            const ResponseNuevaDireccionRecolecion = await axios.put(URL_EDITARDIRECCION_A_RECO, {
                "calle": CalleDireccion,
                "noext": NumeroExtDireccion,
                // "noint": NumIntNueva === undefined ? '' : NumIntNueva,
                "colonia": ColoniaDireccion,
                "cp": CpDireccion,
                "localidad": CiudadDireccion,
                "estado": EstadoDireccion,
                // "idcliente": ClienteSeleccionado.id,
                "pais": PaisDireccion,
                "correo": CorreoDireccion,
                "telefono": TelefonoDireccion,
                // "original": "No",
                "municipio": MunicipioDireccion,
                // "idasesor": user?.id,
                "coordenadas": {
                    "latitude": lat.toString(),
                    "longitude": lng.toString()
                },
                  "tipo": tipo
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
                },
            })

            if (isMounted) {
                // CerrarModalDireccionesCliente()
                setLoadingDireccionPeticion(false)
                setGeocoderData(undefined)
            }
        } catch (error) {
            console.error(error)
        }
    }


//MODAL SIN DIRECCION DE RECOLECCION
   const ActualiazarDireccionARecolecionNuevaPeticion = async (direccion,idusuario) =>{
        setLoadingDireccionPeticion(true)

        let DireccionCadena = `${direccion.calle} ${direccion.noint === "s/n" ? direccion.noext : direccion.noin},${ColoniaDireccion},${direccion.cp},${direccion.localidad},${direccion.estado}.,${direccion.pais}`
        try {
            let isMounted = true;
            const source = axios.CancelToken.source();
            const URL_EDITARDIRECCION_A_RECO = `${config.API_URL}Cliente/Address/update/${direccion.id}/${idusuario}` 
           // await GeocoderObtenerDireccion(DireccionCadena)
           const URL_GEOCODER = `${config.API_URL}Geolocalizacion/geocodereverse/${DireccionCadena}`
           const ResponseGeocoder = await axios.get(URL_GEOCODER, {
               cancelToken: source.token
             });
                    const ResponseNuevaDireccionRecolecion = await axios.put(URL_EDITARDIRECCION_A_RECO,{
                        "coordenadas": {
                            "latitude": ResponseGeocoder.data.lat,
                            "longitude": ResponseGeocoder.data.lng
                          },
                          "tipo": 2
                    },{
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
                          },
                    })

                    if(isMounted){
                        // CerrarModalDireccionesCliente()
                        setLoadingDireccionPeticion(false)
                        setGeocoderData(undefined)
                    }

        } catch (error) {
            console.error(error)
        }
    }

    const ActuliazarDireccionARecolecionPETICION = async (direccion, idusuario, tipo, lat, lng) => {
        let DireccionCadena = `${direccion.calle} ${direccion.noint === "s/n" ? direccion.noext : direccion.noin},${ColoniaDireccion},${direccion.cp},${direccion.localidad},${direccion.estado}.,${direccion.pais}`

        try {
            let isMounted = true;
            const source = axios.CancelToken.source();
            const URL_EDITARDIRECCION_A_RECO = `${config.API_URL}Cliente/Address/update/${direccion.id}/${idusuario}`
            GeocoderObtenerDireccion(DireccionCadena)
            const ResponseNuevaDireccionRecolecion = await axios.put(URL_EDITARDIRECCION_A_RECO, {
                "tipo": tipo,
                "coordenadas": {
                    "latitude": lat.toString(),
                    "longitude": lng.toString()
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
                },
            })

            if (isMounted) {

                setGeocoderData(undefined)
            }

        } catch (error) {
            console.error(error)
        }
    }


    const GeocoderObtenerDireccion = async (direccion) => {
        setGeocoderData()
        try {
            let isMounted = true;
            const source = axios.CancelToken.source();
            const URL_GEOCODER = `${config.API_URL}Geolocalizacion/geocodereverse/${direccion}`
            const ResponseGeocoder = await axios.get(URL_GEOCODER, {
                cancelToken: source.token
            });

            if (isMounted) {

                setGeocoderData(ResponseGeocoder.data)
            }
        } catch (error) {

        }
    }
    return {
        CargarDirecciones,
        CargarDireccionesDeRecoleccion,
        Direcciones,
        PrimeraDireccion,
        DireccionesRecoleccion,
        ActuliazarDireccionARecolecion,
        GeocoderData,
        LimpiarGeocoder,
        GeocoderObtenerDireccion,
        AñadirNuevaDireccion,
        SeleccionarDireccion,
        DireccionSeleccionada,
        ActuliazarDireccionARecolecionPETICION,
        LoadingDireccionPeticion,
        LoadingDireccionPeticionTes,
        LoadingNuevaDireccion,
        //DIRECCION
        HanlderCambioLatitudeDireccion,
        LatitudeDireccion,
        HanlderCambioLongitudeDireccion,
        LongitudeDireccion,
        PaisDireccion,
        HandlerCambioPaisDireccion,
        CalleDireccion,
        HandlerCambioCalleDireccion,
        NumeroExtDireccion,
        HandlerCambioNumExtDireccion,
        NumeroIntDireccion,
        HandlerCambioNumIntDireccion,
        CpDireccion,
        HandlerCambioCpDireccion,
        ColoniaDireccion,
        HandlerCambioColoniaDireccion,
        CiudadDireccion,
        HandlerCambioCiudadDireccion,
        MunicipioDireccion,
        HandlerCambioMunicipioDireccion,
        EstadoDireccion,
        HandlerCambioEstadoDireccion,
        CorreoDireccion,
        HandlerCambioCorreoDireccion,
        TelefonoDireccion,
        HandlerCambioTelefonoDireccion,
        BuscarDir,
        HandlerBuscarDir,
        cliente,
        LimpiarDireccion,
        HandlerSubmenuDir,
        SubmenuDirecciones,
        ActualiazarDireccionARecolecionNuevaPeticion,
        // LimpiarGeocoderNuevoCliente
    }
}

export default useDirecciones