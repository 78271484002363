import React, { useState, useEffect } from 'react'
import { SidebarLayout } from '../../theme/Layout'
import useSiedemenu from '../../utils/hooks/useSiedemenu'
import Flechadoble from '../../assets/iconos/flechadoble'
import IconCasa from '../../assets/iconos/iconCasa'
import { useAuth } from '../../context/authcontext'
import MonitoreoIcon from '../../assets/iconos/MonitoreoIcon'
import { useNavigate } from "react-router-dom";
import PeticionIcon from '../../assets/iconos/PeticionIcon';
import ReporteIcon from '../../assets/iconos/ReporteIcon';
import configuracionIcon from '../../assets/iconos/configuracionIcon.svg';
import flechaabajo from '../../assets/iconos/flechaabajoselect.webp'
import Permisos from '../../utils/permisos'
import { type } from '@testing-library/user-event/dist/type'
const Sidemenu = (props) => {
    const{UsuariosPermisoSuc}=Permisos()
    const navigate = useNavigate();
    const { user, handleractiveMenu } = useAuth()
    const [Active, setActive] = useState(true)
    const [MenuActivado, setMenuActivado] = useState(localStorage.getItem("lastmenu"))
    const [Activesubmenu, setActivesubmenu] = useState(false)
    const [activeConfig, setactiveConfig] = useState(false)
    const [activeMapa, setactiveMapa] = useState(false)

const FueraSubmenu = (e, type) => {
    e.preventDefault()
    if (type === Activesubmenu) {
        setActivesubmenu(true)
    }
     else{
        setActivesubmenu()
    }
}
const FueraSubmenuConfig = (e, type) => {
    if (type === activeConfig) {
        setactiveConfig(true)
    }
     else{
        setactiveConfig()
    }
}
const FueraSubmenuMapa = (e, type) => {
    if (type === activeMapa) {
        setactiveMapa(true)
    }
     else{
        setactiveMapa()
    }
}
    const handlerActiveMenuSide = () => {
        if (Active) {
            setActive(false)
            props.accionMenu(false)
        } else {
            setActive(true)
            props.accionMenu(true)

        }
    }

    const HandlerCambioMenu = async (data) => {
        await handleractiveMenu(data)
        setMenuActivado(data)
        setActivesubmenu(false)
    }
    const HandelrActivesubmenu = async (data) => {
        if (Activesubmenu === true) {
            setActivesubmenu(false)
            // await handleractiveMenu(data)
            setMenuActivado(data)

        } else {
            setActivesubmenu(true)
            // await handleractiveMenu(data)
            setMenuActivado(data)

        }
    }
    const HandelrSubmenuConfig = async () => {
        if (activeConfig === true) {
            setactiveConfig(false)
            // await handleractiveMenu(data)
            // setMenuActivado(data)

        } else {
            setactiveConfig(true)
            // await handleractiveMenu(data)
            // setMenuActivado(data)

        }
    }
    const HandelrSubmenuMapa = async () => {
        if (activeMapa === true) {
            setactiveMapa(false)
            // await handleractiveMenu(data)
            // setMenuActivado(data)

        } else {
            setactiveMapa(true)
            // await handleractiveMenu(data)
            // setMenuActivado(data)

        }
    }

    const HandlerGoSubmenu = (nombre, navegacion) => {
        HandlerCambioMenu(nombre)
        navigate(navegacion, { relative: "path", replace: true })
    }

    const HanlderSubMenuClick = async(data) => {
        await handleractiveMenu(data)
        setMenuActivado(data)
    }
    return (

        <SidebarLayout
            flechaActive={
                props.activemenu === false ? '359deg' : '182deg'
            }
            BodyColor={props.Darkmode === false ? '#EFF0F2' : 'red'}
        >
 
            <ul className="containermenu">
                {/* INICIO */}
                <li className='item-menu-o' onClick={() => HandlerCambioMenu("inicio")} style={Active === true ? {padding: 0} : {background: 'transparent'}}>
                    <div onClick={() => navigate("/", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "inicio" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <IconCasa color="#6A6A6A"></IconCasa>
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Inicio</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "inicio" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>

                    </div>
                </li>

                {/* MAPA*/}
                  <li className='item-menu-o' onClick={() => HandlerCambioMenu("Monitoreo")} style={Active === true ? {padding: 0} : {background: 'transparent'}}>
                    <div onClick={() => navigate("/MapaMonitor", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Monitoreo" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <MonitoreoIcon color="#6A6A6A"></MonitoreoIcon>
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Monitoreo</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "Monitoreo" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>

                    </div>
                </li>
                
                 {/*<li className='item-menu-o' onClick={() => HandelrSubmenuMapa()} style={Active === true ? {padding: 0} : {background: 'transparent'}}>
                    <div className={[`containermenuflex ${MenuActivado === "Mapa anterior" || MenuActivado === "Mapa nuevo" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <MonitoreoIcon color="#6A6A6A"></MonitoreoIcon>
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Monitoreo</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "Monitoreo" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>
                    </div>
                    {
                        activeMapa === true ?
                            <div className={props.activemenu === false ? 'Submenu-Activemenu' : 'Submenu-Activemenu-Small'} style={activeMapa === true ? { opacity: 1, padding: 20, paddingRight:11, bottom:-27 } : { opacity: 0, width: 0, height: 0 }}>
                                <ul onMouseLeave={(e) => FueraSubmenuMapa(e)} className='BodyactiveSubmenu'>
                                    <div onClick={()=>  HanlderSubMenuClick("Mapa anterior")} style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                                    <li onClick={() =>  navigate("/mapaciudad", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Monitoreo" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Mapa ciudad</li>
                                    </div>
                                    <div  onClick={()=>  HanlderSubMenuClick("Mapa nuevo")} style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                                    <li onClick={() => navigate("/MapaMonitor", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Monitoreo" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Mapa ciudad principal</li>
                                    </div>
                                </ul>
                            </div>
                            :
                            <></>
                    }

                </li>*/}
                {/* MULTIPLE */}
                {/* <li className='item-menu-o' onClick={() => HandelrSubmenuMapa()}>
                    <div className={[`containermenuflex ${MenuActivado === "Monitoreo" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <MonitoreoIcon color="#6A6A6A"></MonitoreoIcon>
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Monitoreo</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "Monitoreo" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>
                    </div>
                    {
                        Activesubmenu === true ?
                            <div className={props.activemenu === false ? 'Submenu-Activemenu' : 'Submenu-Activemenu-Small'} style={Activesubmenu === true ? { opacity: 1, padding: 10 } : { opacity: 0, width: 0, height: 0 }}>
                                <ul className='BodyactiveSubmenu'>
                                    <li onClick={() => HandlerGoSubmenu("Monitoreo", "/mapaciudad")}>Mapa Ciudad</li>
                                    <li>Zona</li>
                                </ul>
                            </div>
                            :
                            <></>
                    }

                </li> */}
                {/* PETICIONES */}
                <li className='item-menu-o' onClick={() => HandlerCambioMenu("Peticiones")} style={Active === true ? {padding: 0} : {background: 'transparent'}}>
                    <div onClick={() => navigate("/Peticiones", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Peticiones" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <PeticionIcon color="#6A6A6A"></PeticionIcon>
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Peticiones</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "Peticiones" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>
                    </div>
                </li>

                {/* REPORTES */}
                <div style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                {/* <li className='item-menu-o' onClick={() => HandlerCambioMenu("Reportes")} style={Active === true ? {padding: 0} : {background: 'transparent'}}>
                    <div onClick={() => navigate("/Reportes", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Reportes" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <ReporteIcon color="#6A6A6A"></ReporteIcon>
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Reportes</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "Reportes" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>
                    </div>
                </li> */}
                <li className='item-menu-o' onClick={() => HandelrActivesubmenu()} style={Active === true ? {padding: 0} : {background: 'transparent'}}>
                    <div className={[`containermenuflex ${MenuActivado === "Reporte peticiones" || MenuActivado === "Reporte ganancia" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <ReporteIcon color="#6A6A6A"></ReporteIcon>
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Reportes</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "Reportes" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>
                    </div>
                    {
                        Activesubmenu === true ?
                            <div className={props.activemenu === false ? 'Submenu-Activemenu' : 'Submenu-Activemenu-Small'} style={Activesubmenu === true ? { opacity: 1, padding: 10, paddingRight:11 } : { opacity: 0, width: 0, height: 0 }}>
                                <ul onMouseLeave={(e) => FueraSubmenu(e)} className='BodyactiveSubmenu'>
                                    <div  onClick={()=>  HanlderSubMenuClick("Reporte peticiones")} >
                                    <li onClick={() => navigate("/Reportes", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Reportes" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Reporte peticiones</li>
                                    </div>
                                    <div onClick={()=>  HanlderSubMenuClick("Reporte ganancia")} style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                                    <li onClick={() => navigate("/ReporteGanancias", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Reportes" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Reporte Ganancia</li>
                                    </div>
                                </ul>
                            </div>
                            :
                            <></>
                    }

                </li>
                <li className='item-menu-o' onClick={() => HandelrSubmenuConfig()} style={Active === true ? {padding: 0} : {background: 'transparent'}}>
                    <div className={[`containermenuflex ${MenuActivado === "Zonas" || MenuActivado === "Direcciones" || MenuActivado === "Calendario" || MenuActivado === "Presupuesto" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>
                        <div className='indicadormenu' style={props.activemenu === true ? { opacity: 0 } : { opacity: 1 }}></div>
                        <img style={{height:'30px', paddingLeft:'9px'}} src={configuracionIcon} alt="" />
                        <p className='containerRoutesidemenu' style={props.activemenu === true ? { opacity: 0, width: 0 } : { opacity: 1 }} >Configuración</p>
                        <div className={[`conatinerindicadorActivemenu ${MenuActivado === "Configuracion" && props.activemenu === false ? "active" : ''}`]}>
                            <div className='indicador-sidebar'></div>
                        </div>
                    </div>
                    {
                        activeConfig === true ?
                            <div className={props.activemenu === false ? 'Submenu-Activemenu' : 'Submenu-Activemenu-Small'} style={activeConfig === true ? { opacity: 1, padding: 20, paddingRight:11, bottom:-27 } : { opacity: 0, width: 0, height: 0 }}>
                                <ul onMouseLeave={(e) => FueraSubmenuConfig(e)} className='BodyactiveSubmenu'>
                                <div onClick={()=>  HanlderSubMenuClick("Zonas")} style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                                    <li onClick={() => navigate("/Zonas", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Configuracion" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Zonas</li>
                                    </div>
                                    <div onClick={()=>  HanlderSubMenuClick("Direcciones")} style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                                    <li onClick={() =>  navigate("/Direcciones", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Configuracion" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Direcciones</li>
                                    </div>
                                    {/* <div  onClick={()=>  HanlderSubMenuClick("Calendario")} style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                                    <li onClick={() => navigate("/Calendario", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Configuracion" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Calendario</li>
                                    </div> */}
                                    <div  onClick={()=>  HanlderSubMenuClick("Presupuesto")} style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? {background:'transparent'} : {display:'none'}}>
                                    <li onClick={() => navigate("/Presupuesto", { relative: "path", replace: true })} className={[`containermenuflex ${MenuActivado === "Configuracion" && props.activemenu === true ? "active" : ''}`]} style={props.activemenu === true ? { paddingRight: 5, paddingLeft: 5 } : { opacity: 1 }}>Presupuestos</li>
                                    </div>
                                </ul>
                            </div>
                            :
                            <></>
                    }

                </li>
                </div>
                {/* FIN */}
            </ul>
          
            <div className='ContainerFlechaSideMenu' onClick={() => handlerActiveMenuSide()}>
                <Flechadoble
                    color="#000"
                ></Flechadoble>
            </div>
        </SidebarLayout>
    )
}

export default Sidemenu