import React, { useState, useEffect, useRef } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authcontext';
import { MapaMonitorLayout, PeticionesLayout, TablaLayout } from '../theme/Layout';
import UsePeticiones from '../components/hooks/UsePeticiones';
import UseRecolectores from '../components/hooks/UseRecolectores';
import DragPetitionComponent from '../components/Petiticiones/DragAndDrop/DragPetitionComponent';
import Swal from 'sweetalert2';
import useAsignarRecolector from '../components/Petiticiones/hooks/useAsignarRecolector';
import { useParams } from 'react-router-dom';
import ModalComponent from '../utils/Components/modal/ModalComponent';
import useModal from '../utils/Components/modal/usemodal';
import Permisos from '../utils/permisos';
import NuevoIcon from '../assets/iconos/NuevoIcon';
import TablaPaginador from '../utils/Components/Tablas/paginador/TablaPaginador';
import InputComponent from '../utils/Components/Inputs/InputBuscador';
import useTablaPaginador from '../utils/Components/Tablas/paginador/hook/UseTablaPaginador';
import moment from 'moment';
import imgReasignarReco from '../assets/iconos/reasignar.svg';
import imgEditEstatus from '../assets/iconos/editTabla.svg';
import imgRecolector from '../assets/imagenes/AvatarInicio.webp';
import TablaIcon from '../assets/iconos/TablaIcon.svg';
import TableroIcon from '../assets/iconos/TableroIcon.svg';

const Peticiones = () => {
  let Tablapaginador = useRef()
  const [IsCancelar, setIsCancelar] = useState(false)
  const esEstatusValidoAceptada = ["A", "R", "D", "T", "C"]
  const estatusValidoRecolectando = ["R", "D", "T"]
  const estatusValidoDocumentando = ["D", "T"]
  const [isselected, setisselected] = useState();
  const [selectPeticion, setselectPeticion] = useState()
  const EstatusArray = [
    {
      "value": "A",
      "nombre": "Asigando"
    },
    {
      "value": "P",
      "nombre": "Pendiente"
    },
    {
      "value": "R",
      "nombre": "Recolectando"
    }
    ,
    {
      "value": "D",
      "nombre": "Documentando"
    },
    {
      "value": "T",
      "nombre": "Terminado"
    },
    {
      "value": "Todos",
      "nombre": "Todos"
    },

  ]
  const [EstatusEditar, setEstatusEditar] = useState(false)
  const estatusValidoTerminada = ["T"]
  const { perimisos } = Permisos()
  const navigate = useNavigate()
  const params = useParams()
  const { HandlerAsignarRecolector, HandlerDesignarRecolector } = useAsignarRecolector()
  const { user } = useAuth();
  const {
    PeticionesMapa,
    LoadPeticiones,
    ActualizarPeticion,
    Page,
    Pages,
    hanldernext,
    handlerprev,
    TOTALdeRegistros,
    HanlderChangueRegistros,
    HanlderCambioFecha,
    FechaBuscador,
    HanlderBuscador,
    Search,
    HanlcerCambioFiltroEstatus,
    FiltroEstatus
  } = UsePeticiones()
  const { ModalEstatusPeticion,
    AbrirModalEstatusPeticion,
    CerrarModalEstatusPeticion,
    ModalCambioRecolector,
    AbrirModalCambioRecolector,
    CerrarModalCambioRecolector } = useModal()
  const { LoadRecolectores, Recolectores } = UseRecolectores()
  const [VistaPeticion, setVistaPeticion] = useState(false)
  const [Banderavista, setBanderavista] = useState(null)
  const [PeticionSeleccionada, setPeticionSeleccionada] = useState()

  const [hanlderactiveSubmenu, sethanlderactiveSubmenu] = useState(false);
  const [active, setactive] = useState(false);

  //LISTA PETICIONES OPCIONES 
  const handlerSelectedrec = (data) => {

    if (isselected === data) {
      setisselected()
      sethanlderactiveSubmenu(false)
    } else {
      setisselected(data)
      sethanlderactiveSubmenu(true)
    }
  }
  const handlerFueraAcciones = (e, type) => {
    e.preventDefault()
    if (type === active) {
      setisselected(true)
    } else {
      setisselected()
    }
  }
  const HandleModalCambioRecolector = (e, PeticionSeleccionada) => {
    setPeticionSeleccionada(PeticionSeleccionada)
    AbrirModalCambioRecolector(e)
  }
  const HandlerCerrarModalCambioRecolector = () => {
    CerrarModalCambioRecolector()
    setPeticionSeleccionada()
  }

  const HanlderCancler = async () => {
    setIsCancelar(true)
  }

  const HanlderCerrarModal = () => {
    setIsCancelar(false)
    CerrarModalEstatusPeticion()
  }

  useEffect(() => {
    if (VistaPeticion === false && Banderavista === "DRAG") {
      // window.location.reload()
    }
  }, [VistaPeticion])

  useEffect(() => {
    LoadRecolectores(JSON.parse(localStorage.getItem("Ciudad")).idciudad)
  }, [user])
  // useEffect(() => {
  //   var nuevoElemento = {
  //     "id": 0,
  //     "idsucursal": JSON.parse(localStorage.getItem("Ciudad")).idciudad,
  //     "descripcion": "Pendiente",
  //     "zona": 0,
  //     "nombreusuarioasignado": "Pendiente"
  // };
  //   if(Recolectores){
  //     addUniqueObject(nuevoElemento)
  //   }
  // }, [Recolectores])


  // useEffect(() => {
  //   if(Recolectores){
  //     var nuevoElemento = {
  //       "id": 0,
  //       "idsucursal": JSON.parse(localStorage.getItem("Ciudad")).idciudad,
  //       "descripcion": "Pendiente",
  //       "zona": 0,
  //       "nombreusuarioasignado": "Pendiente"
  //   };

  //   Recolectores.push(nuevoElemento);
  //   }
  // }, [Recolectores])
  // FUNCION PARA DETECTAR 
  useEffect(() => {
    if (!Tablapaginador) {
      let handlerActionPetition = (e) => {
        if (!Tablapaginador.current.contains(e.target)) {

          // hanlderactiveSubmenu()
          // setisselected()
          // sethanlderactiveSubmenu(false)
        }
      }
      document.addEventListener("mousedown", handlerActionPetition)
    }



  }, [])

  useEffect(() => {
    if (PeticionesMapa === undefined) {
      LoadPeticiones(moment().format('YYYY-MM-DD'), JSON.parse(localStorage.getItem("Ciudad")).idciudad, '', 1, 1000)
    }
  }, [PeticionesMapa])

  const { socket } = useAuth()

  // LLAMADAS DE SOCKET
  useEffect(() => {
    if (!socket) return;
    socket.on('UpdateStatusPetition', (data) => {
      LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    })
  }, [socket])
  useEffect(() => {
    if (!socket) return;
    socket.on('FinishDoc', (data) => {
      LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    })
  }, [socket])
  useEffect(() => {
    if (!socket) return;
    socket.on('NewPetition', (data) => {
      LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    })
  }, [socket])
  useEffect(() => {
    if (!socket) return;
    socket.on('AssignPicker', (data) => {
      LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    })
  }, [socket])

  useEffect(() => {
    if (!socket) return;
    socket.on('AssignedPicker', (data) => {
      LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    })
  }, [socket])

  const handlerActiveView = () => {
    if (VistaPeticion === true) {
      setVistaPeticion(false)
      // LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
      setBanderavista("DRAG")
    } else {
      setVistaPeticion(true)
      LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad, '', 1, 10)
      setBanderavista("TABLA")
    }
  }

  const AbrirModal = (PeticionSeleccionada) => {
    setPeticionSeleccionada(PeticionSeleccionada)
    AbrirModalEstatusPeticion()
  }
  const CambiarEstatus = async (data, motivo) => {
    HanlderCerrarModal()
    await ActualizarPeticion(PeticionSeleccionada.id, PeticionSeleccionada.idusuarioasignado, data, user.id, motivo)
    HanlderCerrarModal()
  }
  const ActivarNextEstatus = () => {
    if (EstatusEditar === true) {
      setEstatusEditar(false)
    } else {
      setEstatusEditar(true)
    }
  }

  const RenderItem = () => {

    return (
      <div>

        <div className='ContainerModalDetallesPeticion'>
          <div className='itemSoliPet'>
            <h3>Origen</h3>
            <div className='ContainerOrigenDetallesPeticion'>
              <img src={PeticionSeleccionada?.urlorigen} width={30} />
            </div>
          </div>
          <div className='itemSoliPet'>
            <h3>Cliente</h3>
            <div>
              <p>{PeticionSeleccionada?.cliente}</p>
            </div>
          </div>
          <div className='itemSoliPet'>
            <h3>Fecha Promesa</h3>
            <div>
              {/* <p>{PeticionSeleccionada?.fechapromesa}</p> */}
              <p>{moment(PeticionSeleccionada?.fechapromesa).format('DD/MM/YYYY')}</p>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className='SeparadorHistorialDetallesPeticion'>
              <h4>Historial de petición</h4>
            </div>
            <MapaMonitorLayout>
              <ul

                className='TimelinePeticiones TimlineDetallesPetiones'>
                <li
                  style=
                  {
                    esEstatusValidoAceptada.includes(PeticionSeleccionada?.estatus)
                      ?
                      {
                        opacity: 1,

                      }
                      :
                      { display: 'none', width: 0, height: 0 }
                  }>
                  <div className='conatineridicador-Timlinepeticiones'>
                    <div className='Indicadorlinea-Timlinepeticiones'></div>
                    <span className='IndicadorStep-Timlinepeticiones'
                      style=
                      {
                        esEstatusValidoAceptada.includes(PeticionSeleccionada?.estatus)
                          ?
                          {
                            opacity: 1,
                            background: '#3BAF2A'

                          }
                          :
                          { display: 'none', width: 0, height: 0 }
                      }
                    ></span>
                    <p>Aceptada</p>
                  </div>
                </li>
                <li
                  style=
                  {
                    estatusValidoRecolectando.includes(PeticionSeleccionada?.estatus)
                      ?
                      {
                        opacity: 1,

                      }
                      :
                      { display: 'none', width: 0, height: 0 }
                  }
                >
                  <div className='conatineridicador-Timlinepeticiones'>
                    <div className='Indicadorlinea-Timlinepeticiones'></div>
                    <span className='IndicadorStep-Timlinepeticiones'
                      style=
                      {
                        estatusValidoRecolectando.includes(PeticionSeleccionada?.estatus)
                          ?
                          {
                            opacity: 1,
                            background: '#FFB531'

                          }
                          :
                          { display: 'none', width: 0, height: 0 }
                      }
                    ></span>
                    <p>Recolectando</p>
                  </div>
                </li>
                <li
                  style=
                  {
                    estatusValidoDocumentando.includes(PeticionSeleccionada?.estatus)
                      ?
                      {
                        opacity: 1,

                      }
                      :
                      { display: 'none', width: 0, height: 0 }
                  }
                >
                  <div className='conatineridicador-Timlinepeticiones'>
                    <div className='Indicadorlinea-Timlinepeticiones'></div>
                    <span className='IndicadorStep-Timlinepeticiones'
                      style=
                      {
                        estatusValidoDocumentando.includes(PeticionSeleccionada?.estatus)
                          ?
                          {
                            opacity: 1,
                            background: '#35C1ED'

                          }
                          :
                          { display: 'none', width: 0, height: 0 }
                      }
                    ></span>
                    <p>Documentando</p>
                  </div>
                </li>
                <li
                  style=
                  {
                    estatusValidoTerminada.includes(PeticionSeleccionada?.estatus)
                      ?
                      {
                        opacity: 1,

                      }
                      :
                      { display: 'none', width: 0, height: 0 }
                  }>
                  <div className='conatineridicador-Timlinepeticiones'>
                    <div className='Indicadorlinea-Timlinepeticiones'></div>
                    <span className='IndicadorStep-Timlinepeticiones'
                      style=
                      {
                        estatusValidoTerminada.includes(PeticionSeleccionada?.estatus)
                          ?
                          {
                            opacity: 1,
                            background: '#151B4E'

                          }
                          :
                          { display: 'none', width: 0, height: 0 }
                      }
                    ></span>
                    <p>Terminada</p>
                  </div>
                </li>

                {
                  IsCancelar === true ?
                    <>
                    </>
                    :
                    <li style={PeticionSeleccionada?.estatus === "C" || PeticionSeleccionada?.estatus === "T" ? { display: 'none' } : { background: 'transparent' }} className='ContainerEditarPEticionDetalles'>
                      <div className='BotonEditerPeticionEstatusDetalles' onClick={() => ActivarNextEstatus()} style={!EstatusEditar ? { position: 'relative' } : { display: 'none' }}>
                        <p>Editar</p>
                      </div>
                      <div className='conatineridicador-Timlinepeticiones' style={EstatusEditar ? { position: 'relative' } : { display: 'none' }}>
                        <div className='Indicadorlinea-Timlinepeticiones EditarDireccionPeticionDetalle' >
                          <span
                            style={{
                              opacity: 1,
                              background: PeticionSeleccionada?.estatus === "A" ? "#FFB531" : PeticionSeleccionada?.estatus === "R" ? "#35C1ED" : PeticionSeleccionada?.estatus === "D" ? "#151b4e" : "#3BAF2A"
                            }}
                            className='IndicadorStep-Timlinepeticiones'></span>
                          <p>{PeticionSeleccionada?.estatus === "A" ? "Recolectando" : PeticionSeleccionada?.estatus === "R" ? "Documentando" : PeticionSeleccionada?.estatus === "D" ? "Terminar" : "A"}</p>
                        </div>
                      </div>
                      <div className='BotonCancelarPeticionEstatusDetalles' onClick={() => HanlderCancler()} style={!EstatusEditar ? { position: 'relative' } : { display: 'none' }}>
                        <p>Cancelar</p>
                      </div>
                    </li>
                }

              </ul>
              {
                IsCancelar === true ?
                  <>
                    <div className='' style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                      <button onClick={() => CambiarEstatus("C", "Cliente cancelo el envìo")} className='BotonCancelar'>Cancelar</button>
                    </div>
                  </>
                  :
                  <>
                    <div className='' style={EstatusEditar ?
                      { width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }
                      : { display: 'none' }
                    }>
                      <button
                        onClick={() => CambiarEstatus(PeticionSeleccionada?.estatus === "A" ? "R" : PeticionSeleccionada?.estatus === "R" ? "D" : PeticionSeleccionada?.estatus === "D" ? "T" : "A", undefined)}
                        className='BotonAceptar'>Actualizar</button>
                      {/* <button onClick={() => CambiarEstatus("C","Cliente cancelo el envìo")} className='BotonCancelar'>Cancelar</button> */}
                    </div>
                  </>
              }
            </MapaMonitorLayout>
          </div>
        </div>
      </div>
    )
  }


  // FILTROS PAGINADOR
  // FILTROS
  const HanlderCambiarEstatus = async (e) => {
    HanlcerCambioFiltroEstatus(e)
    const data = EstatusArray?.filter(datos => datos.nombre == e);
    if (e === "Todos") {
      await LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad, '')
    } else {
      await LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad, data[0].value)
    }

  }
  const HanlderBusqueda = async () => {
    await LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad, Search)
  }
  const HanlderCambioFechaFiltro = async (e) => {
    let fehca = moment(e).format('YYYY-MM-DD')

    await HanlderCambioFecha(fehca)
    await LoadPeticiones(fehca, JSON.parse(localStorage.getItem("Ciudad")).idciudad, Search)
  }
  const RenderItemFiltrosPaginador = () => {
    return (
      <TablaLayout

      >
        <div className='CamposFiltrosPaginaror'>
          <div className='CamposFiltrosPaginaror-Izquierdo'>
            <div style={{ flex: 3 }}>
              <InputComponent
                nombre={"Buscar:"}
                tipo={"Buscador2"}
                Accion={HanlderBuscador}
                flex={1}
                AccionBoton={HanlderBusqueda}
              ></InputComponent>
            </div>

            <div style={{ flex: 2 }}>
              <InputComponent
                nombre={"Fecha:"}
                tipo={"Date"}
                flex={1}
                value={FechaBuscador}
                Accion={HanlderCambioFechaFiltro}
              ></InputComponent>
            </div>

            <div style={{ flex: 1 }}>
              <InputComponent
                nombre={"Estatus:"}
                tipo={"Select"}
                flex={1}
                datos={EstatusArray}
                Accion={HanlderCambiarEstatus}
                value={FiltroEstatus}
              ></InputComponent>
            </div>
          </div>
        </div>
      </TablaLayout>
    )
  }
  // CABEZERA PAGINA
  const RenderItemCabezeraTablaPaginador = () => {
    return (
      <>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
          <p>Origen</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
          <p>Recolector</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
          <p>Cliente</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 4 }}>
          <p>Dirección</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
          <p>Estatus</p>
        </div>
        <div className='ItemHeader-TablaPaginador campoFecha' style={{ flex: 1 }}>
          <p>Hora</p>
        </div>
        <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
          <p></p>
        </div>
      </>
    )
  }
  // const RenderItemCambioRecolector = () => {
  //   return (
  //     <PeticionesLayout>
  //     <div className='Cuerpo-ListaModal'>
  //       {
  //         Recolectores?.map(resp => {
  //           return (
  //             <>
  //               <div className="ItemCuerpoLista">
  //                 <div style={{width:'45px', paddingRight:'10px'}}>
  //                 <img style={{width:'100%'}} src={imgRecolector} alt="" />
  //                 </div>
  //                 <div>
  //                 <p>{resp.descripcion}</p>
  //                 </div>
  //               </div></>
  //           )
  //         })
  //       }
  //     </div>
  //     </PeticionesLayout>
  //   )
  // }
  const RenderItemCuerpoTablaPaginador = () => {
    return (
      <div>
        <>
          {
            PeticionesMapa?.map((res, index) => {
              return (
                <>
                  <div className='Cuerpo-TablaSinPaginador' style={{ height: '60px' }} >
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2, paddingTop: '5px' }}>
                      <img src={res.urlorigen} width={30} />
                      <p style={{ margin: '0px' }}> -{res.id}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                      <p>{res.nombreusuarioasignado}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3 }}>
                      <p>{res.cliente}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 4, textAlign: 'justify', padding: '3px' }}>
                      <p>{res.direccion} </p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2 }}>
                      <p>{res.estatus === 'A' ? "Aceptada" :
                        res.estatus === 'D' ? "Documentando" :
                          res.estatus === 'R' ? "Recolectando" :
                            res.estatus === 'T' ? "Terminada" :
                              res.estatus === 'P' ? "Pendiente" :
                                "Cancelada"} </p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador campoFecha' style={{ flex: 1 }}>
                      <p>{moment(res.fecha).format('DD/MM/YYYY hh:mm A')}</p>
                    </div>
                    <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, position: 'relative', height: '66px' }}>
                      <div
                        style={res.estatus === "T" ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                        className={`ContainerActions ${res.id === isselected ? "active" : ""}`} onClick={res.estatus === "T" ? '' : () => handlerSelectedrec(res.id)}>
                        <span style={res.id === isselected ? { background: '#fff', zIndex: 4, fontWeight: 'bold' } : { background: '#9597A7' }}></span>
                        <span style={res.id === isselected ? { background: '#fff', zIndex: 4, fontWeight: 'bold' } : { background: '#9597A7' }}></span>
                        <span style={res.id === isselected ? { background: '#fff', zIndex: 4, fontWeight: 'bold' } : { background: '#9597A7' }}></span>
                      </div>
                      {/* <div className="ContainerSubAction" onMouseLeave={(e) => handlerFueraAcciones(e)} style={res.id !== isselected ? { display: 'none' } : { background: '#D9D9D9' }}> */}
                      <div className="ContainerSubAction" onMouseLeave={(e) => handlerFueraAcciones(e)}
                        style={res.id !== isselected
                          ?
                          { display: 'none' }
                          :
                          (PeticionesMapa.length > 1 ? index > PeticionesMapa.length - 3 : false)
                            ? { display: 'block', bottom: '57px' }
                            : { display: 'block', top: '57px' }

                        }>
                        <div className="ItemSubmenu" onClick={() => handlerSelectedrec(res.id)}>
                          <img style={{ width: '34px' }} src={imgReasignarReco} alt="" />
                          <p onClick={(e) => HandleModalCambioRecolector(e, res)}>Reasignar recolector</p>
                        </div>
                        <div className="ItemSubmenu" onClick={() => handlerSelectedrec(res.id)}>
                          <img style={{ width: '31px' }} src={imgEditEstatus} alt="" />
                          <p onClick={() => AbrirModal(res)}>Modificar estatus</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          }
          <ModalComponent
            Nombremodal={ModalCambioRecolector}
            Cerrarmodal={HandlerCerrarModalCambioRecolector}
            Titulo={"Seleccionar recolector"}
            Contenido={RenderItemCambioRecolector}
          ></ModalComponent>

          <ModalComponent
            Nombremodal={ModalEstatusPeticion}
            Cerrarmodal={HanlderCerrarModal}
            Titulo={"Solicitud de petición"}
            Contenido={RenderItem}
          ></ModalComponent>
        </>
      </div>

    )
  }

  const handleDragEnd = async (result) => {

    let idpetition = result.draggableId;
    let recolectorAsignado = result.destination.droppableId;
    let recolectororigen = result.source.droppableId;
    let Dataacambiar = Recolectores?.filter((usuario) => usuario.id == result.destination.droppableId)
    let dataPeticionacambiar = PeticionesMapa?.filter((task) => task.id == result.draggableId)
    Swal.fire({
      title: `Esta por Reasignar la petición del cliente: ${dataPeticionacambiar[0]?.cliente} al recolector: ${Dataacambiar[0]?.descripcion}`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      denyButtonText: `Cancelar`,
      denyButtonColor: "#DC1D1D",
      confirmButtonColor: "#3BAF29"
    }).then((result) => {
      if (result.isConfirmed) {
        if (recolectorAsignado != 0) {
          HandlerAsignarRecolector(recolectorAsignado, idpetition)
        } else {
          HandlerDesignarRecolector(recolectororigen, idpetition)
          LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad)

        }
      }
    })
  }
  const AsignarRecolector = (peticion, usuarioasignado, recolector, nombre) => {

    Swal.fire({
      title: `Esta por reasignar la petición del cliente: ${PeticionSeleccionada.cliente} al recolector: ${nombre}`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      denyButtonText: `Cancelar`,
      denyButtonColor: "#DC1D1D",
      confirmButtonColor: "#3BAF29"
    }).then((result) => {
      if (result.isConfirmed) {
        if (recolector != 0) {
          HandlerAsignarRecolector(recolector, peticion)
          HandlerCerrarModalCambioRecolector()
        } else {
          HandlerDesignarRecolector(usuarioasignado, peticion)
          HandlerCerrarModalCambioRecolector()
        }
      }
    })
  }
  const HanlderIrNuevo = () => {
    if (perimisos.includes(params.Peticiones)) {
      navigate('/Peticiones/nuevo')
    }
  }
  const RenderItemCambioRecolector = () => {
    return (
      <PeticionesLayout>
        <div className='Cuerpo-ListaModal'>
          {
            Recolectores?.map(resp => {

              return (
                <>
                  <div onClick={(result) => AsignarRecolector(PeticionSeleccionada.id, PeticionSeleccionada.idusuarioasignado, resp.id, resp.descripcion)} className="ItemCuerpoLista">
                    <div style={{ width: '45px', paddingRight: '10px' }}>
                      {/* <img style={{width:'100%'}} src={imgRecolector} alt="" /> */}
                    </div>
                    <div>
                      <p>{resp.descripcion}</p>
                    </div>
                  </div></>
              )
            })
          }
        </div>
      </PeticionesLayout>
    )
  }

  const HanlderNavegaraTabla = (data) => {
    setVistaPeticion(true)
    LoadPeticiones(FechaBuscador, JSON.parse(localStorage.getItem("Ciudad")).idciudad, '', 1, 10)
    setBanderavista("TABLA")
  }
  return (
    <div id={VistaPeticion === true ? 'ContenedorPrincipalSinfondo' : 'ContenedorPrincipal'} >
      <PeticionesLayout>
        {/* <div className='ConainerSeleccion' >
        <p onClick={() => handlerActiveView()} >Cambiar de vista a  {VistaPeticion === true ? 'tablero' : 'tabla'}</p>
          <div onClick={() => handlerActiveView()} className="btnCambioVista">
            <img src={VistaPeticion === true ? TableroIcon : TablaIcon} alt="" />
          </div>
      </div> */}

        <TablaLayout
        >
          <div className={VistaPeticion === true ? 'ContenedorTablaFiltrosPaginador' : 'ContenedorTablaFiltrosPaginadorTablero'}>
            <div className='containerFiltrosPaginador'>
              {VistaPeticion ? RenderItemFiltrosPaginador() : ''}
            </div>
            <div className="botonesFiltro">
              <div className='ConainerSeleccion' >
                {/* <p onClick={() => handlerActiveView()} >Cambiar de vista a  {VistaPeticion === true ? 'tablero' : 'tabla'}</p> */}
                <div onClick={() => handlerActiveView()} className="btnCambioVista">
                  <img src={VistaPeticion === true ? TableroIcon : TablaIcon} alt="" />
                </div>
              </div>
              <div onClick={() => HanlderIrNuevo()} className='iconnuevo-peticion'>
                <NuevoIcon width={40} height={40}></NuevoIcon>
              </div>
            </div>
          </div>
        </TablaLayout>
        <div className='ContainerPaginaPeticiones' style={VistaPeticion === true ? { position: 'relative' } : { overflowX: 'scroll' }}>
          {
            VistaPeticion === false
              ?
              <>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <div className='ContainerDrag'>
                    {
                      Recolectores?.map(resp => {

                        return (
                          <>
                            <div className='card-Drag-Peticiones'>
                              <div className='containertitlecard'>
                                <h3 className='Headertitle-card'>{resp.descripcion}</h3>
                                {/* <p style={{cursor: 'pointer'}} onClick={() => handlernavigatelistpetition(resp.nombreusuarioasignado)}>...</p> */}
                                <p
                                  style={
                                    { cursor: 'pointer' }
                                  }
                                  onClick={() => HanlderNavegaraTabla(resp.nombreusuarioasignado)}
                                >...</p>
                              </div>
                              <DragPetitionComponent
                                title={resp.descripcion}
                                id={JSON.stringify(resp.id)}
                                Peticiones={PeticionesMapa?.filter((task) => task.idusuarioasignado === resp.id)}
                                AccionAbrirModal={AbrirModal}
                              ></DragPetitionComponent>
                            </div>
                          </>
                        )
                      })
                    }

                  </div>
                </DragDropContext>
              </>
              :
              <>
                <TablaPaginador
                  Cabezera={RenderItemCabezeraTablaPaginador}
                  Cuerpo={RenderItemCuerpoTablaPaginador}
                  identificador={Tablapaginador}
                  pagina={Page}
                  totalpaginas={Pages}
                  Accionnext={hanldernext}
                  Accionprev={handlerprev}
                  RegistrosTotales={TOTALdeRegistros}
                  CambiarRegistros={HanlderChangueRegistros}
                ></TablaPaginador>
              </>
          }
          <ModalComponent
            Nombremodal={ModalEstatusPeticion}
            Cerrarmodal={HanlderCerrarModal}
            Titulo={"Solicitud de petición"}
            Contenido={RenderItem}
          ></ModalComponent>
        </div>
      </PeticionesLayout>
    </div>
  )
}
export default Peticiones
