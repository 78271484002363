import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { MapaMonitorLayout, Layoutcontainer, PeticionesLayout } from '../theme/Layout'
import Swal from 'sweetalert2'
import Zonas from './Zonas'
import MapaMonitor from '../components/Mapa/MapaMonitor'
import MapaZonas from '../components/Mapa/MapaZonas'
import { useAuth } from '../context/authcontext';
import InputComponent from '../utils/Components/Inputs/InputBuscador'
import UsePeticiones from '../components/hooks/UsePeticiones'
import UseDirections from '../components/Mapa/hooks/UseDirections'
import useGetZona from '../components/Mapa/hooks/useGetZona'
import useSucursalesActivas from '../components/hooks/useSucursalesActivas'
import UseRecolectores from '../components/hooks/UseRecolectores'
import { UseAgregarReco } from '../components/hooks/useAgregarReco'
import useErrorAlert from '../utils/hooks/useErrorAlert'
import moment from 'moment'
import PeticionesPendientes from '../components/Mapa/PeticionesPendientes'
import SucrusalIcon from '../assets/imagenes/sucursalnvo.webp'
import UsemapMonitor from '../components/Mapa/hooks/UsemapMonitor'
import useCiudades from '../components/hooks/useCiudades'
import NuevoIcon from '../assets/iconos/NuevoIcon'
import infoZonas from '../assets/iconos/verZonas.svg'
import editarIcon from '../assets/iconos/EditarIcon.svg'
import AvatarNuevo from '../assets/imagenes/AvatarInicio.webp'
import imgAsignar from '../assets/imagenes/userCheck.svg';
import imgSinAsignar from '../assets/imagenes/userDontCheck.png';
// import MapaZonas from '../components/Mapa/MapaZonas'
import Mapaprueba from '../components/Mapa/mapaprueba';

const EditarZona = () => {
  let dimension = window.innerWidth;
  const navigate = useNavigate();
  const { HanlderVerMensajeError } = useErrorAlert()
  const { Ciudades, CargarCiudades } = useCiudades()
  const { CargarSucursales, SucursalesData } = useSucursalesActivas()
  const {
    LoadZonas,
    Datazona,
    handleraddcords,
    cordsaddfance,
    LoadZonasPorCiudad,
    DataZonaUsuario,
    geoJsonZona,
    NuevaZonaMapa,
    handlereditzona,
    SelectZona,
    EditarZonaMapa,
    seleccionarZona,
    HandlerCambioCoordenadas,
  } = useGetZona()
  // const { VistaAgregarNuevaZona } = UsemapMonitor()
  const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))
  const { user, CiudadSeleccionada, socket, RecolectoresMapa, HandleEditReco } = useAuth()
  const { LoadPeticionesReco, PeticionesRecolectando, PeticionesDocumenando, PeticionesActivas, LoadPeticiones, PeticionesMapa } = UsePeticiones()
  const { Recolectores, LoadRecolectores } = UseRecolectores();
  const [RecolectorSelecionado, setRecolectorSelecionado] = useState()
  const { GetRoutes, Ruta, LimpiarRuta } = UseDirections();
  const {
    recoGeo,
    HandlerRecolectorGeo,
    selectRecoGeo,
    HandlerSelectRecolectorZona,
    HandlerSelectRecolectorZona2 } = UseAgregarReco();
  const [RutaEncurso, setRutaEncurso] = useState();
  const [AbrirPeticionesPendientes, setAbrirPeticionesPendientes] = useState(false);
  const [Peticionespendientes, setPeticionespendientes] = useState([])
  const [zonaSeleccionada, setzonaSeleccionada] = useState(false)
  const [NuevaZona, setNuevaZona] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [nombre, setnombre] = useState('');
  const [EditarZona, setEditarZona] = useState(false)
  const [editarRecolector, seteditarRecolector] = useState()
  const [editarColor, seteditarColor] = useState()
  // const [SelectRecoGeo, setSelectRecoGeo] = useState(false)

  const HandlerAbrirPeticionesPendientes = () => {
    if (AbrirPeticionesPendientes === true) {
      setAbrirPeticionesPendientes(false)
    } else {
      setAbrirPeticionesPendientes(true)
    }
  }
  //mapa
  useEffect(() => {
    if (PeticionesMapa != undefined) {
      const data = PeticionesMapa.filter(datos => datos.estatus === "P")
      setPeticionespendientes(data)
    }
  }, [PeticionesMapa])

  const HandlerRutaEncurso = async (data) => {
    await setRutaEncurso(data)
  }

  useEffect(() => {
    if (RecolectorSelecionado) {

    } else {
      LimpiarRuta()
    }
  }, [RecolectorSelecionado])

  useEffect(() => {
    if (PeticionesMapa === undefined) {
      setTimeout(() => {
        LoadPeticiones(FechAactual, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        LoadZonas()
        CargarSucursales()
      }, 2000);
    }
  }, [PeticionesMapa])

  useEffect(() => {
    if (!DataZonaUsuario) {
      LoadZonasPorCiudad()
    }
  }, [DataZonaUsuario, LoadZonasPorCiudad])

  const SeleccionarReco = (data) => {
    if (data.id === RecolectorSelecionado?.id) {
      setRecolectorSelecionado(undefined)
    } else {
      setRecolectorSelecionado(data)
      LoadPeticionesReco(data.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }
  }
  useEffect(() => {
    LoadRecolectores(JSON.parse(localStorage.getItem("Ciudad")).idciudad)
  }, [user])

  //CIUDADES
  useEffect(() => {
    if (!Ciudades) {
      CargarCiudades()
    }
  }, [Ciudades, CargarCiudades])

  let idCiudadSeleccionada = Ciudades?.map(res => {
    return res.id
  })

  const activarZona = () => {
    if (zonaSeleccionada === true) {
      setzonaSeleccionada(false)
    } else {
      setzonaSeleccionada(true)
    }
  }
  const handlerRecoPorZona = (data) => {
    setzonaSeleccionada(data)
  }

  //NUEVA ZONA
  const HandlerNuevaZonaBtn = () => {
    navigate('/Zonas')
  }
  const handlechanguenombre = (data) => {
    setnombre(data)
  }
  const handlerAgregarZona = async (e) => {
    if (recoGeo.length === 0) {
      HanlderVerMensajeError('No hay recolectores seleccionados.')
    } if (nombre === '' || nombre === undefined) {
      HanlderVerMensajeError('Favor de asignar nombre a la zona que desea agregar.')
    } else {
      let color;
      let disabled2 = false;
      e.preventDefault()
      const ArrayPorCiudad = Datazona === "" ? [] : Datazona?.filter(datos => JSON.stringify(datos.idciudad).includes(JSON.stringify(CiudadSeleccionada.idciudad)));
      // if (ArrayPorCiudad.length === 0) {
      //   color = "#3BAF29"
      // } else if (ArrayPorCiudad?.length === 1) {
      //   color = "#35C1ED"
      // } else if (ArrayPorCiudad?.length === 2) {
      //   color = "#1EE27B"
      // } else if (ArrayPorCiudad?.length === 3) {
      //   color = "#F98500"
      // } else if (ArrayPorCiudad?.length === 4) {
      //   color = "#FFB531"
      // } else if (ArrayPorCiudad?.length === 5) {
      //   color = "#EDA15C"
      // } else if (ArrayPorCiudad?.length > 5) {
      //   disabled2 = true
      //   setdisabled(true)
      // }
      if (ArrayPorCiudad.length === 0) {
        color = "#1EE27B"
      } else if (ArrayPorCiudad?.length === 1) {
        color = "#35C1ED"
      } else if (ArrayPorCiudad?.length === 2) {
        color = "#F29400"
      } else if (ArrayPorCiudad?.length === 3) {
        color = "#007AEC"
      } else if (ArrayPorCiudad?.length === 4) {
        color = "#F4DF27"
      } else if (ArrayPorCiudad?.length === 5) {
        color = "#E0A2EB"
      } else if (ArrayPorCiudad?.length > 5) {
        disabled2 = true
        setdisabled(true)
      }
      var data = JSON.stringify({
        "idfranquicia": user.idsucursal,
        "cordenadas": cordsaddfance,
        "estatus": "A",
        "nombre": nombre,
        "recolectores": recoGeo?.map(res => res.id),
        "idciudad": CiudadSeleccionada.idciudad,
        "color": color
      })
      if (color === undefined) {
        HanlderVerMensajeError('Se alcanzó el máximo de zonas para la ciudad.')
      } else {
        NuevaZonaMapa(data)
      }
    }
  }

  //   const activRecolectorGeo = (id) => {
  //     if (SelectRecoGeo === true) {
  //       setSelectRecoGeo(false)
  //     } else{
  //       setSelectRecoGeo(true)
  //     }
  // }


  //EDITAR ZONA
  // const  HandlerCambioNombreZona = (data) => {
  // setnombre(data)
  // }
  const HanlderEditarZona = async (e) => {
    e.preventDefault()
    var dataEditarZona = JSON.stringify({
      "idciudad": CiudadSeleccionada.idciudad,
      "idfranquicia": user.idsucursal,
      "nombre": nombre,
      "color": editarColor,
      "cordenadas": cordsaddfance,
      "recolectores": recoGeo?.map(res => res.id),
      "estatus": "A"
    })
    EditarZonaMapa(dataEditarZona)
    // HanlderMensajeCorrecto('Se editó la dirección correctamente')
    // LimpiarNueva()
  }
  // const HandlerCambioCoordenadas = () => {

  // }
  const HanlderCambioColor = (data) => {
    seteditarColor(data)
  }
  // const HanlderCambioRecolectores = (data) => {
  //   seteditarRecolector(data)
  // }
  const HandlerSelectZona = async (resp) => {
    SelectZona(resp)
    handlechanguenombre(resp.observaciones)
    HandlerCambioCoordenadas(resp.cords)
    HanlderCambioColor(resp.color)
    // HanlderCambioRecolectores(resp.recolectores)
  }
  const HandlerEditar = (resp) => {
    HandlerSelectZona(resp)
    banderaEditar()
  }
  const banderaEditar = () => {
    if (EditarZona === true) {
      setEditarZona(false)
    } else {
      setEditarZona(true)
    }
  }

  return (
    <MapaMonitorLayout
      SucrusalIcon={SucrusalIcon}
    >
      <Layoutcontainer>
        <div className="containerZonas" style={{ position: 'relative' }}>
          <div className='ContainerMapaZonas'>
            <div className='containermonitorZonas' >
            {/* <Mapaprueba
            Zoom="10">
            </Mapaprueba> */}
              <MapaZonas
                Datazona={Datazona}
                Sucursales={SucursalesData}
                GeoJsonZona={geoJsonZona}
                Accionguardarcordenada={handleraddcords}
                Zoom="10"
              ></MapaZonas>
              <div className='iconNuevaZona' onClick={() => HandlerNuevaZonaBtn()}>
                <img style={{ width: '35px', height: '35px' }} src={infoZonas} alt="" />
              </div>
              <div className='contenedorInfoNuevaZona' style={{ cursor: 'auto' }}>
                <div className="contenedorNuevaZona">
                  <div className="tituloZona">
                    <p>Editar zona</p>
                  </div>
                  {/* CONTENEDOR ZONAS */}

                  {/* CONTENEDOR SELECCIONAR RECOLECTORES NUEVA ZONA */}
                  <div className="contenedorRecolectoresNuevaZona">
                    <p>Seleccionar recolectores</p>
                    <div className="contenedorSeleccionarRecoZona">
                      {
                        Recolectores?.map((resp, index) => {
                          let nombrecompleto = resp.descripcion;
                          var nombre = nombrecompleto.split(' ')[0];
                          var apellido = nombrecompleto.split(' ')[2]
                          // var resultname = name[0];
                          return (
                            <>
                              <div className="recolectorZona" onClick={() => HandlerSelectRecolectorZona2(resp.id, resp.descripcion, index)} style={resp.nombreusuarioasignado === "Pendiente" ? { display: 'none' } : { display: 'flex' }}>
                                <div className='nombreRecolector'>
                                  <p style={{ cursor: 'pointer' }}>{nombre} {apellido}</p>
                                </div>
                                <div className="asignar">
                                  <img src={JSON.stringify(recoGeo).includes(resp.descripcion) === true ? imgAsignar : imgSinAsignar} alt="" />
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>

                  {/* CONTENEDOR RECOLECTORES DE CADA ZONA */}

                  {/* CONTENEDOR GUARDAR NUEVA ZONA */}
                  <div className="contenedorGuardarZona">
                    <div>
                      <p style={{marginBottom:'-10px'}}>Asignar nombre</p>
                      <div className="inputNombreZona" style={{ paddingLeft: '14px' }}>
                        <InputComponent
                          //  nombre={"nombre"}
                          tipo={"Text"}
                          flex={1}
                          Accion={handlechanguenombre}
                          value={nombre}
                        ></InputComponent>
                        <PeticionesLayout>
                          <div className='Boton_Aceptar'>
                            <button onClick={(e) => handlerAgregarZona(e)}>Guardar</button>
                            {/* <button onClick={(e) => HanlderEditarZona(e)}>Editar</button> */}
                          </div>
                        </PeticionesLayout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layoutcontainer>
    </MapaMonitorLayout>
  )
}
export default EditarZona