import React, { useEffect, useState } from 'react'
import Onsiteimg from '../../assets/imagenes/Onsite.webp';
import Sucursalimg from '../../assets/imagenes/sucursalnvo.webp';
import flecha from '../../assets/iconos/flechaabajoselect.webp'
import ruta from '../../assets/iconos/ruta.webp'
import moment from 'moment'
import Loader from '../../utils/Components/Loader/Loader';

// IMAGENES 
import SucursalPng from '../../assets/imagenes/sucursalnvo.webp';
import OnsitePng from '../../assets/imagenes/Onsite.webp'
import imgRecoleccionActivo from '../../assets/iconos/recoleccionActivo.svg'
import imgEntregaActivo from '../../assets/iconos/entregaActivo.svg'
import imgCobranzaActivo from '../../assets/iconos/cobranzaActivo.svg'
import imgBancoActivo from '../../assets/iconos/bancoActivo.svg'
import imgOtroActivo from '../../assets/iconos/otroActivo.svg'

const DetallesREco = (props) => {
  // console.log(props.RecolectorSelecionado,"MARIO LOPEZ RAMMIREZ 22")
  const [PeticionCurso, setPeticionCurso] = useState()
  const [CordenadaOrigen, setCordenadaOrigen] = useState()
  const [CordenadasDestino, setCordenadasDestino] = useState()
  const [Tiempo, setTiempo] = useState()
  const [Seleccionado, setSeleccionado] = useState()
  const esEstatusValidoAceptada = ["A", "R", "D", "T", "C"]
  const estatusValidoRecolectando = ["R", "D", "T"]
  const estatusValidoDocumentando = ["D", "T"]
  const estatusValidoTerminada = ["T"]
  const [SelectedPeticion, setSelectedPeticion] = useState();


  const HandlerSelecionRuta = (id, data) => {
    if (SelectedPeticion === id) {
      setSelectedPeticion()
      if (props.PeticionesRecolectando?.length >= 1) {
        setCordenadasDestino(`${props.PeticionesRecolectando[0].coordenadas.longitude},${props.PeticionesRecolectando[0].coordenadas.latitude}`)
        props.HandlerRutaEncurso(props.PeticionesRecolectando[0])

      } else if (props.PeticionesDocumenando?.length >= 1) {
        setCordenadasDestino(`${props.PeticionesDocumenando[0].coordenadas.longitude},${props.PeticionesDocumenando[0].coordenadas.latitude}`)
        props.HandlerRutaEncurso(props.PeticionesDocumenando[0])
      } else {
        setCordenadasDestino(undefined)
        props.HandlerRutaEncurso(undefined)
      }
    } else {
      setSelectedPeticion(id)
      setCordenadasDestino(`${data.coordenadas.longitude},${data.coordenadas.latitude}`)
      props.AccionCargaRuta(CordenadaOrigen, CordenadasDestino)
      props.HandlerRutaEncurso(data)
    }
  }

  const selecionarPeticiones = (data) => {
    if (Seleccionado === data) {
      setSeleccionado()
    } else {
      setSeleccionado(data)
    }
  }

  useEffect(() => {
    setSeleccionado("Todas")
    if (props.RecolectorSelecionado) {
      setCordenadaOrigen(`${props.RecolectorSelecionado.lng},${props.RecolectorSelecionado.lat}`)
    } else {
      setCordenadaOrigen(undefined)
    }
  }, [props.RecolectorSelecionado, setCordenadaOrigen])

  useEffect(() => {
    if (props.PeticionesRecolectando?.length >= 1) {
      setCordenadasDestino(`${props.PeticionesRecolectando[0].coordenadas.longitude},${props.PeticionesRecolectando[0].coordenadas.latitude}`)
      props.HandlerRutaEncurso(props.PeticionesRecolectando[0])

    } else if (props.PeticionesDocumenando?.length >= 1) {
      setCordenadasDestino(`${props.PeticionesDocumenando[0].coordenadas.longitude},${props.PeticionesDocumenando[0].coordenadas.latitude}`)
      props.HandlerRutaEncurso(props.PeticionesDocumenando[0])
    } else {
      setCordenadasDestino(undefined)
      props.HandlerRutaEncurso(undefined)
    }
  }, [props.PeticionesRecolectando, props.PeticionesDocumenando, setCordenadasDestino])


  useEffect(() => {
    if (CordenadaOrigen && CordenadasDestino) {
      props.AccionCargaRuta(CordenadaOrigen, CordenadasDestino)
    }
  }, [CordenadaOrigen, CordenadasDestino])


  useEffect(() => {
    if (props.RecolectorSelecionado) {
    }
    else {
      setCordenadasDestino(undefined)
      setCordenadaOrigen(undefined)
    }

  }, [props.RecolectorSelecionado])

  useEffect(() => {
    if (props.Ruta) {
      // setTiempo(duration.format("mm"))
    }
  }, [props.Ruta])

  // const duration = moment.duration(props.Ruta?.routes[0].duration, 'seconds');
  // const minutos = duration.asMinutes();

  useEffect(() => {
    if (props.Ruta) {
      const duration = moment.duration(props.Ruta?.routes[0].duration, 'seconds');
      const minutos = duration.asMinutes();
      const minutosFormateados = Math.floor(minutos);
      setTiempo(minutosFormateados)
    }
  }, [props.Ruta])

  //TIPO PETICIONES
  const imagesTypePetition = (type) => {
    if (type == 1) {
        return (imgRecoleccionActivo)
    }
    if (type == 2) {
        return (imgEntregaActivo)
    }
    if (type == 3) {
        return (imgBancoActivo)
    }
    if (type == 4) {
        return (imgCobranzaActivo)
    }
    if (type == 5) {
        return (imgOtroActivo)
    }
}
 
console.log(props.PeticionesRecolectando, "PeticionesRecolectando")
console.log(props.PeticionesDocumenando, "PeticionesDocumenando")
  return (
   <div>
    {
        props.RecolectorSelecionado === undefined ? 
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', margin: 'auto' }}>
              <Loader
                title="Cargando recolector"
              ></Loader>
            </div>
          </>
      :
      <div className=''>
      <div className='conatinerFelx-Header-Reco'>
        <div>
          <p className='NombrerecoDetalles'>{props.RecolectorSelecionado?.name}</p>
          {/* <p>{props.RecolectorSelecionado?.vehiculo}</p> */}
        </div>
      </div>
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'88%', margin:'auto'}}>
      <div className='ContianerTitleRecoDetalle'>
        <h3 className='containerPeticionesTitle'>Peticiones</h3>
      </div>
      {/* <div className='ConatinerTotalPeticiones-DetallesReco'>
        <p>Total de peticiones:</p>
        <p className='ContainerTotal-Total-DetallesReco'>{props.PeticionesActivas === undefined ? 0 : props.PeticionesActivas?.length}</p>
      </div> */}
        <div className='ConatinerTotalPeticiones-DetallesReco'>
        <p style={{fontSize:'12px'}}>Total </p>
        <p>{props.PeticionesActivas === undefined ? 0 : props.PeticionesActivas?.length}</p>
      </div>
      </div>
      <div className='ContainerDetallesRecos '>
      <div
        style={props.PeticionesRecolectando?.length >= 1 || props.PeticionesDocumenando?.length >= 1 ? { background: 'transparent' } : { display: 'none' }}
        className='Container-PeticionCurso'>
        {/*<p className='Titulo-PeticionCurso'>{props.PeticionesRecolectando ? props.PeticionesRecolectando[0]?.estatus == "R" ? 'Recolectando' : props.PeticionesDocumenando[0]?.estatus == "D" ? 'Documentando' : '' : ''}</p>*/}
          <div className='ContainerWidgetRecoDetalle'>
              <div className='BodyContainerWidgetRecoDetalle'>
                <div className='ContainerOrigneWidgetRecoDetalle'>
                  <img src={ imagesTypePetition(props.PeticionesRecolectando ?  props.PeticionesRecolectando[0]?.idTipoPeticion || props.PeticionesDocumenando[0]?.idTipoPeticion : '')}/>
                </div>
                {/* <div className='ContainerOrigneWidgetRecoDetalle'>
                  <img src={props.PeticionesRecolectando ?  props.PeticionesRecolectando[0]?.origen === "SUCURSAL" ? SucursalPng : OnsitePng : ''} alt="" srcset="" />
                </div> */}
                <div className='TextInfoWidgetRecoDetalle'>
                  <p style={{fontSize:'13px'}}>{props.PeticionesRecolectando ?  props.PeticionesRecolectando[0]?.id || props.PeticionesDocumenando[0]?.id : ''}</p>
                  <p style={{fontWeight:'bold', color:'#151B4E', textTransform:'uppercase'}}>{props.PeticionesRecolectando ?  props.PeticionesRecolectando[0]?.cliente || props.PeticionesDocumenando[0]?.cliente : ''}</p>
                </div>
              </div>

              <div className='ContainerInfoFooterRecodetalle'>
                <div className='ContainerProgressbar'>
                  <p>Estatus</p>
                  {/* <ul className='ContainerEstatusProgressbar'>
                    <li className='AceptedProgressBar'></li>
                    <li className='RecoProgressBar'></li>
                    <li  className='DocuProgressBar'></li>
                  </ul> */}
                  
                  <ul style={props.PeticionesRecolectando ? props.PeticionesRecolectando[0]?.estatus == "R" ? {display:'flex'} : {display:'none'}: {display:'none'}} className='ContainerEstatusProgressbar'>
                    <li className='AceptedProgressBar'></li>
                    <li className='RecoProgressBar'></li>
                  </ul>
                  <ul style={props.PeticionesDocumenando ? props.PeticionesDocumenando[0]?.estatus == "D" ? {display:'flex'} : {display:'none'}: {display:'none'}} className='ContainerEstatusProgressbar'>
                    <li className='AceptedProgressBar'></li>
                    <li className='RecoProgressBar'></li>
                    <li className='DocuProgressBar'></li>
                  </ul>
                </div>
                <div className='continerTiempo'>
                <p>
            {Tiempo} min
          </p>
                </div>
              </div>
          </div>
      </div>
      </div>
      <div className='SelecionarPeticiones'>
        <div
          // style={Seleccionado === "Todas" ? { background: 'green', color: '#fff', cursor:'pointer'  } : { background: 'transparent', cursor:'pointer' }}
          onClick={() => selecionarPeticiones("Todas")}
          className={`BotonSelecionarPetciones-Detalles ${Seleccionado === "Todas" ?  'Active' : ''}`}>
          <p>Todas</p>
        </div>
        <div
          // style={Seleccionado === "Terminadas" ? { background: 'green', color: '#fff',cursor:'pointer' } : { background: 'transparent', cursor:'pointer' }}
          onClick={() => selecionarPeticiones("Terminadas")}
          className={`BotonSelecionarPetciones-Detalles ${Seleccionado === "Terminadas" ?  'Active' : ''}`}>
          <p>Terminadas</p>
        </div>
      </div>

      <div>
        {
          Seleccionado === "Todas" ?
            <>
              {
                props.PeticionesActivas?.map(res => {
                  return (
                    <>
                      <div
                        style={
                          res.estatus === "R" || res.estatus === "D" ?
                            {
                              display: 'none'
                            }
                            :
                            {
                              display: 'flex',
                              flexDirection: 'column',
                              paddingRight: 10,
                              paddingLeft: 10,
                              width: '85%',
                              marginLeft: '5%',
                              height: 90,
                              marginBottom: 10,
                              marginTop: 10
                            }
                        }
                        onClick={() => HandlerSelecionRuta(res.id, res)}
                        className='ContainerWidgetRecoDetalle'
                      >
                        <div style={{
                          display: 'flex', alignItems: 'center',
                          width: '100%',
                          justifyContent: 'center',
                        }}>
                          <img style={{ width: '50px'}}
                            src={imagesTypePetition(res.idTipoPeticion)}
                          ></img>
                          {/* <img style={{ width: '50px'}}
                            src={res.origen == "SUCURSAL" ? Sucursalimg : Onsiteimg}
                          ></img> */}
                          <div style={{display:'flex', flexDirection:'column'}}>
                          <p style={{ width: 146, paddingLeft: 10, marginBottom:'0px', fontSize:'13px', color:'#333333', fontWeight:'bold'}} >{res.id}</p>
                          <p style={{ width: 146, paddingLeft: 10,   marginTop: '0px', textTransform:'uppercase', fontSize:'14px', color:'#151B4E', fontWeight:'bold'}} >{res.cliente}</p>
                          </div>
                          <div style={{cursor:'pointer'}}>
                          <img src={ruta}></img>
                          </div>
                        </div>
                        <div>
                       
                        </div>
                      </div>

                    </>
                  )
                })
              }
            </>
            :
            <>
            </>
        }
      </div>
    </div>
    }
   </div>
  )
}

export default DetallesREco