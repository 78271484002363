import React,{useState} from 'react';
import axios from 'axios'
import config from '../../config/config';
import UsePeticiones from './UsePeticiones';
const UseRecolectores = () =>{
    const [RecolectorSeleccionado, setRecolectorSeleccionado] = useState()
    const [Recolectores, setRecolectores] = useState();
    const [LoadingRecolectores, setLoadingRecolectores] = useState(false)
    const{LoadPeticionesReco}=UsePeticiones()

    const HandlerCambioRecolector = (data) => {
        setRecolectorSeleccionado(data)
    }
    // const addUniqueObject = (newObject) => {
    //   const existingObject = data.find(item => item.id === newObject.id);
      
    //   if (existingObject) {
    //     console.error(`El ID ${newObject.id} ya existe en el arreglo.`);
    //     // Puedes realizar alguna acción adicional aquí, como mostrar un mensaje de error o actualizar el objeto existente.
    //   } else {
    //     data.push(newObject);
    //     console.log(`Objeto con ID ${newObject.id} agregado correctamente.`);
    //   }
    // };
    const LoadRecolectores = async(idciudad) =>{
        setLoadingRecolectores(true)
        let isMounted = true;
        const source = axios.CancelToken.source();
        let URLRECOS = `${config.API_URL}Usuario/GetUserCity/${idciudad}`
        setTimeout(() => {
            source.cancel('Request timed out');
            // console.log("CARGA RECOLECTOR")
        }, 17000);
        try {
            const responseRecos = await axios.get(URLRECOS, {
                cancelToken: source.token
              });
              if (isMounted) {
                if(responseRecos.status === 204){
                    setRecolectores([])
                }else{
                  setLoadingRecolectores(false)
                  var nuevoElemento = {
                    "id": 0,
                    "idsucursal": JSON.parse(localStorage.getItem("Ciudad")).idciudad,
                    "descripcion": "Pendiente",
                    "zona": 0,
                    "nombreusuarioasignado": "Pendiente"
                };
            
                    setRecolectores(responseRecos.data)
                    const existingObject = responseRecos.data.find(item => item.id === nuevoElemento.id);
                    if (existingObject) {
                     
                      // Puedes realizar alguna acción adicional aquí, como mostrar un mensaje de error o actualizar el objeto existente.
                    } else {
                      responseRecos.data.push(nuevoElemento);
                    
                    }
                    // setRecolectorSeleccionado(responseRecos.data[0])
                    // CAMBIAR LA CIUDAD
                    //LoadPeticionesReco(responseRecos.data[0].id,1)
                
  //   Recolectores.push(nuevoElemento);
                }
               
              }
        } catch (error) {
            
        }
    }
  return {
    LoadRecolectores,
    RecolectorSeleccionado,
    Recolectores,
    HandlerCambioRecolector,
    LoadingRecolectores
  }
}

export default UseRecolectores