import React,{useState} from 'react'
import config from '../../config/config';
import axios from 'axios';

const useTipoPeticiones = () => {
    const [TipoPeticionesPaginador, setTipoPeticionesPaginador] = useState()

    const TipoPeticiones = async() => {
        let isMounted = true;
        const source = axios.CancelToken.source();
        let URLTIPOPETICIONES = `${config.API_URL}TipoPeticiones/Paginador?pagina=1&registrosPorPagina=10&estatus=true`
        try {
            const responseTipoPeticiones = await axios.get(URLTIPOPETICIONES, {
                cancelToken: source.token
              });
              if (isMounted) {
                setTipoPeticionesPaginador(responseTipoPeticiones.data.resultado)
                console.log(responseTipoPeticiones, "responseTipoPeticiones")
              }
        } catch (error) {
            console.error(error)
        }
    }

  return {
    TipoPeticiones,
    TipoPeticionesPaginador
  }
}

export default useTipoPeticiones
