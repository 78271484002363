import React from 'react'

const ReporteIcon = (props) =>{
    return(
<svg width="30" height="36" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.8125 7H35.9375C37.1807 7 38.373 7.55312 39.2521 8.53769C40.1311 9.52226 40.625 10.8576 40.625 12.25V47.25C40.625 48.6424 40.1311 49.9777 39.2521 50.9623C38.373 51.9469 37.1807 52.5 35.9375 52.5H14.0625C12.8193 52.5 11.627 51.9469 10.7479 50.9623C9.86886 49.9777 9.375 48.6424 9.375 47.25V12.25C9.375 10.8576 9.86886 9.52226 10.7479 8.53769C11.627 7.55312 12.8193 7 14.0625 7H17.1875" fill={props.color} strokeWidth="3" strokeLinejoin="round"/>
<path d="M32.8125 7H35.9375C37.1807 7 38.373 7.55312 39.2521 8.53769C40.1311 9.52226 40.625 10.8576 40.625 12.25V47.25C40.625 48.6424 40.1311 49.9777 39.2521 50.9623C38.373 51.9469 37.1807 52.5 35.9375 52.5H14.0625C12.8193 52.5 11.627 51.9469 10.7479 50.9623C9.86886 49.9777 9.375 48.6424 9.375 47.25V12.25C9.375 10.8576 9.86886 9.52226 10.7479 8.53769C11.627 7.55312 12.8193 7 14.0625 7H17.1875" fill={props.color} strokeWidth="3" strokeLinejoin="round"/>
<path d="M30.2607 3.5H19.7393C18.33 3.5 17.1875 4.77956 17.1875 6.35797V7.64203C17.1875 9.22044 18.33 10.5 19.7393 10.5H30.2607C31.67 10.5 32.8125 9.22044 32.8125 7.64203V6.35797C32.8125 4.77956 31.67 3.5 30.2607 3.5Z" fill='#d6d6d6' stroke="#6A6A6A" strokeLinejoin="round"/>
</svg>
)
}
export default ReporteIcon
