import React, { useState} from 'react'
import { useAuth } from '../../context/authcontext';
import { useNavigate } from 'react-router-dom';
import useNuevaPeticion from '../Petiticiones/Nuevo/hooks/useNuevapeticion';

const DetallesPendientes = () => {

  const navigate = useNavigate();
  const { 
    infoGuia,  
    SeleccionarGuiaPendiente,
    idGuia } = useAuth();
    const { HanlderCambioTelefonoPeticion } = useNuevaPeticion();
    
    // const informacionClienteGuia = () => {
    //   HanlderCambioTelefonoPeticion(res.telefono)
    // }

    const NavigateNuevaPeticion = (id) => {
      SeleccionarGuiaPendiente(id)
      navigate('/Peticiones/nuevo')
    }
console.log(infoGuia, "infoGuia")

  return (
    <div style={{
        height:'500px',
        background:'#fff',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        // boxShadow: 'rgb(106, 106, 106) -1px 2px 14px -3px'
    }}>
      <p style={{textAlign:'center'}}>Guías pendientes</p>
      {
        infoGuia?.map(res => {
          return(
            <div style={{
              borderBottom:'solid #CCCCCC 1px', 
              width:'90%', 
              cursor:'pointer'}} 
              onClick={() => NavigateNuevaPeticion(res.id)}>
              <p style={{fontSize:'15px'}}>{res.id} - {res.nombre}</p>
              <p style={{fontSize:'15px'}}>{res.telefono}</p>
            </div>
          )
      })
    }
    </div>
  )
}

export default DetallesPendientes
