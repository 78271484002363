import React from 'react'
import useMapMonitorNvo from './hooks/useMapMonitorNvo'

export const MapaMonitorNvo = (props) => {
    
    const { setRef } = useMapMonitorNvo( props.socket,  props.Recolectores, props.RutaRecolector, props.RutaenCurso, props.PeticionesMapa,  props.AccionEditarReco,  props.RecolectorSelecionado,  props.TipoMapa,  props.Sucursales,  props.DataZona,  props.Zoom, props.AccionCargarReco)

  return (
    <div
    ref={setRef}
    className='NewMapMonitor'
    />
  )
}

export default MapaMonitorNvo
